import React from "react";
import { LocalPack } from "../../components/LocalPack";
import { SponsoredAds } from "../../components/SponsoredAds";
import { LocalServiceAd } from "../../components/LocalServiceAd";
import "./DentistSearchPage.scss";
import Nav from "../../components/Nav";
import {
  adCardData,
  locaPackCardData,
  organicData,
  peopleAskFor,
  relatedSearchResults,
  sponsoredAdData,
} from "../../data/DentistSearchPage";
import { MoreBtn } from "../../components/MoreBtn";
import Footer from "../../components/Footer";
import { PeopleAskFor } from "../../components/PeopleAskFor";
import { RelatedSearch } from "../../components/RelatedSearch";
import {
  PPCAds,
  findResult,
  localPack,
  localServiceAds,
  organicResult,
} from "../../data/multipleChoiceQuestion";
import { ROUTE_LINKS } from "../../routeLinks";

const DentistSearchPage = () => {
  // const ADTYPE = "Dentist near me";
  const pageUrl = ROUTE_LINKS.DENTISTSEARCH;
  return (
    <>
      <Nav pageName={"Dentist near me"} />
      <div className="dentistSearchPage">
        {/* Local Service Ads */}
        <LocalServiceAd
          showSponsored={true}
          businessName="
        Dentists | Miami"
          isGoogleGuaranteed={false}
          adCardData={adCardData}
          btnText={"More Dentists"}
          choices={localServiceAds}
          adType={"Local Services Ads"}
          pageUrl={pageUrl}
        />
        {/* Sponsored Ads */}
        {sponsoredAdData.map((ad, index) => (
          <SponsoredAds
            key={index}
            showSponsored={true} // Customize these props as needed
            adData={ad}
            choices={PPCAds} // Pass the dynamic data as a prop
            adType={"PPC Ads"}
            pageUrl={pageUrl}
          />
        ))}
        {/* LocalPack */}
        <LocalPack
          title={"Places"}
          isFlexRow={true}
          hasCustomPosition={true}
          locaPackCardData={locaPackCardData}
          btnText={"More Places"}
          choices={localPack}
          adType={"Local Pack"}
          pageUrl={pageUrl}
        />
        <PeopleAskFor peopleAskFor={peopleAskFor} />
        {/* organic results */}
        {organicData.map((ad, index) => (
          <SponsoredAds
            key={index}
            adData={ad}
            choices={organicResult}
            adType={"Organic"}
            pageUrl={pageUrl}
          />
        ))}

        <RelatedSearch
          data={relatedSearchResults}
          adType={"Related Search"}
          pageUrl={pageUrl}
          choices={findResult}
        />
        <MoreBtn btnText={"More results"} />
      </div>
      <Footer location={"Miami, Florida"} />
    </>
  );
};

export default DentistSearchPage;
