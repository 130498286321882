// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.localServiceAdContainer {
  padding: 2rem 0;
  width: 60%; }
  .localServiceAdContainer .sponsoredInfo {
    display: flex;
    align-items: center;
    gap: 1rem; }
    .localServiceAdContainer .sponsoredInfo .businessName {
      font-size: 2rem;
      color: #202124;
      line-height: 2rem; }
  .localServiceAdContainer .googleGuaranteed {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: -0.7rem; }
    .localServiceAdContainer .googleGuaranteed .googleGuaranteedText {
      text-transform: uppercase;
      font-size: 1.4rem;
      padding: 1rem 0;
      letter-spacing: 1px; }
  .localServiceAdContainer .adCardSection {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 70%; }
  .localServiceAdContainer .moreBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    font-size: 14px;
    font-family: Roboto, arial, sans-serif;
    font-weight: 400;
    width: 100%;
    max-width: 300px;
    height: 36px;
    border-radius: 18px;
    background-color: #dedede;
    top: -1.7rem;
    color: #202124;
    cursor: pointer;
    gap: 1rem; }
    .localServiceAdContainer .moreBtn button {
      background-color: transparent;
      border: none;
      font-size: 1.4rem; }
    .localServiceAdContainer .moreBtn i {
      color: #70757a; }
`, "",{"version":3,"sources":["webpack://./src/components/LocalServiceAd/LocalServiceAd.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,UAAU,EAAA;EAFZ;IAII,aAAa;IACb,mBAAmB;IACnB,SAAS,EAAA;IANb;MAQM,eAAe;MACf,cAAc;MACd,iBAAiB,EAAA;EAVvB;IAcI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,sBAAsB,EAAA;IAjB1B;MAmBM,yBAAyB;MACzB,iBAAiB;MACjB,eAAe;MACf,mBAAmB,EAAA;EAtBzB;IA0BI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,UAAU,EAAA;EA7Bd;IAgCI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,sCAAsC;IACtC,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,eAAe;IACf,SAAS,EAAA;IAhDb;MAkDM,6BAA6B;MAC7B,YAAY;MACZ,iBAAiB,EAAA;IApDvB;MAuDM,cAAc,EAAA","sourcesContent":[".localServiceAdContainer {\n  padding: 2rem 0;\n  width: 60%;\n  .sponsoredInfo {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n    .businessName {\n      font-size: 2rem;\n      color: #202124;\n      line-height: 2rem;\n    }\n  }\n  .googleGuaranteed {\n    display: flex;\n    align-items: center;\n    gap: 0.5rem;\n    margin-bottom: -0.7rem;\n    .googleGuaranteedText {\n      text-transform: uppercase;\n      font-size: 1.4rem;\n      padding: 1rem 0;\n      letter-spacing: 1px;\n    }\n  }\n  .adCardSection {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n    width: 70%;\n  }\n  .moreBtn {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    margin: 0 auto;\n    font-size: 14px;\n    font-family: Roboto, arial, sans-serif;\n    font-weight: 400;\n    width: 100%;\n    max-width: 300px;\n    height: 36px;\n    border-radius: 18px;\n    background-color: #dedede;\n    top: -1.7rem;\n    color: #202124;\n    cursor: pointer;\n    gap: 1rem;\n    button {\n      background-color: transparent;\n      border: none;\n      font-size: 1.4rem;\n    }\n    i {\n      color: #70757a;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
