import React, { useState } from "react";
import "./LocalPackCard.scss";
import user from "../../assets/images/download.png";
import AdPopup from "../AdPopup";
const LocaPackCard = ({ data, isPlace, choices, adType, pageUrl }) => {
  const redirectToWebsite = () => {
    // Redirect to the website URL specific to this card
    window.location.href = data.website;
  };

  const redirectToDirections = () => {
    // Redirect to the directions URL specific to this card
    window.location.href = data.directionsLink;
  };
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen((prev) => !prev);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div className="locaPackCard" onClick={openPopup}>
        <div className="detailContainer">
          <div className="wrapper">
            {data.profileImage && (
              <img
                className={isPlace ? "placeImage" : "userImage"}
                src={data.profileImage}
                alt="profile"
              />
            )}

            <div className="detail">
              <div className="locaPackCard__title ">{data.title}</div>
              {data.guarenteedTag && (
                <div className="guaranteedTag">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 100 100"
                  >
                    <circle cx="50" cy="50" r="40" fill="#00A86B" />
                    <path
                      d="M20 50L40 70L80 30"
                      fill="none"
                      stroke="#FFF"
                      strokeWidth="8"
                      strokeLinecap="round"
                    />
                  </svg>

                  <span className="googleGuaranteedText">
                    Google guaranteed
                  </span>
                </div>
              )}
              <div className="innerDetails">
                <div className="adCardDetail_rating">
                  <span className="rate">{data.rating}</span>
                  <div className="starImages">
                    {" "}
                    {data.starImage &&
                      data.starImage.map((image, index) => (
                        <span className="stars" key={index}>
                          <img src={image} alt={`Star ${index + 1}`} />
                        </span>
                      ))}
                  </div>

                  <span className="reviews">{data.stars}</span>
                  {data.tag && <span> . {data.tag}</span>}
                </div>

                <div className="experience">
                  {data.businessAge && (
                    <span className="businessAge">
                      {data.businessAge + "."}
                    </span>
                  )}

                  {data.address && (
                    <span className="address"> {data.address}</span>
                  )}
                </div>
                <span className="adCardDetail__openHours">
                  {data.openHours}
                </span>
                <span className="adCardDetail__phone">{data.phoneNumber}</span>
                <span className="adCardDetail_phone">{data.onlineService}</span>
                {data.reviewText && (
                  <span className="reviewText">
                    <img className="user" src={user} alt="user" />"
                    {data.reviewText}"
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="adLinks">
            {data.isWebsite && (
              <span className="website" onClick={redirectToWebsite}>
                <i className="fas fa-globe-asia"></i>
                <span>Website</span>
              </span>
            )}
            {data.isDirection && (
              <span className="direction" onClick={redirectToDirections}>
                <i className="fas fa-directions"></i>
                <span>Direction</span>
              </span>
            )}
            {data.isMessage && (
              <div className="messageContainer">
                <span className="message">
                  <svg
                    width={16}
                    fill="#4285f4"
                    focusable="false"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H4V4h16v12z"></path>
                    <path d="M6 12h12v2H6zm0-3h12v2H6zm0-3h12v2H6z"></path>
                  </svg>
                  <span>Message</span>
                </span>
              </div>
            )}
            {data.isPhone && (
              <div>
                <span className="phone">
                  <i className="fas fa-phone-alt"></i>
                  <span>Call</span>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <AdPopup
          popupData={choices}
          compData={data}
          adType={adType}
          onClose={closePopup}
          pageUrl={pageUrl}
        />
      )}
    </>
  );
};

export default LocaPackCard;
