import React, { useState } from "react";
import "./RelatedSearch.scss";
import AdPopup from "../AdPopup";

export const RelatedSearch = ({ data, pageUrl, choices, adType }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen((prev) => !prev);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };
  const dummyTitle = "Related Search";

  return (
    <>
      <div className="RelatedSearchContainer" onClick={openPopup}>
        <div className="RelatedSearchContainer__header">
          <h1>Related Search</h1>
          <i className="fas fa-ellipsis-v"></i>
        </div>
        <ul className="list">
          {data.map((item, index) => (
            <div className="SearchContainer" key={index}>
              <i className="fas fa-search"></i>
              <li key={index}>{item}</li>
            </div>
          ))}
        </ul>
      </div>{" "}
      {isPopupOpen && (
        <AdPopup
          popupData={choices}
          adType={adType}
          onClose={closePopup}
          pageUrl={pageUrl}
          compData={{ title: dummyTitle }}
        />
      )}{" "}
    </>
  );
};
