export const adCardData = [
  {
    title: "Brickell Luxury Motors",
    rating: 4.6,
    stars: "(486)",
    openHours: "Open 9AM",
    phoneNumber: "(786) 789-6395",
    address: "123 Main St, City, State 12345",
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "In-Store Shopping . Delivery",
    isPhone: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Nacho Autos",
    rating: 5.0,
    stars: "(21)",
    businessAge: "29 years in business",
    openHours: "Open 24/7",
    phoneNumber: "(315) 257-1896",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    isPhone: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56' fill='none'><path d='M51.219 22.5633C51.0843 22.1496 50.8366 21.7818 50.5038 21.5014C50.1711 21.221 49.7666 21.0393 49.336 20.9767L36.0383 19.0447L30.093 6.99532C29.8904 6.62072 29.5869 6.31029 29.2171 6.09917C28.8472 5.88804 28.4256 5.78464 28 5.80066V43.7873L39.893 50.0383C40.2783 50.2409 40.7127 50.3317 41.1469 50.3003C41.5811 50.269 41.9979 50.1167 42.3502 49.8609C42.7024 49.605 42.976 49.2557 43.1401 48.8525C43.3042 48.4492 43.3522 48.0081 43.2787 47.579L41.0083 34.335L50.6287 24.955C50.9404 24.6511 51.1609 24.2661 51.2652 23.8434C51.3695 23.4208 51.3535 22.9774 51.219 22.5633Z' fill='%23BEBEBE' fill-opacity='0.1'/><path d='M25.907 6.99533L19.9617 19.0447L6.66399 20.9767C6.23303 21.0392 5.82818 21.2212 5.49524 21.5019C5.1623 21.7826 4.91457 22.1509 4.78007 22.5651C4.64556 22.9793 4.62966 23.4229 4.73415 23.8456C4.83864 24.2684 5.05935 24.6535 5.37133 24.9573L14.9917 34.335L12.7213 47.579C12.6481 48.0078 12.6962 48.4485 12.8602 48.8514C13.0242 49.2543 13.2976 49.6033 13.6494 49.8591C14.0013 50.1149 14.4176 50.2672 14.8515 50.2988C15.2853 50.3305 15.7194 50.2403 16.1047 50.0383L28 43.785V5.84033C27.1693 5.84267 26.3597 6.23 25.907 6.99533Z' fill='%23fbbc04'/></svg>",
    ],
  },
];
export const locaPackCardData = [
  {
    title: "Brickell Luxury Motors",
    rating: 2.6,
    stars: "(486)",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23dadce0' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23dadce0' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],

    openHours: "Open 9AM",
    phoneNumber: "(786) 789-6395",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "In-Store Shopping . Delivery",
    profileImage:
      "https://lh5.googleusercontent.com/p/AF1QipPkYdt4VkSa5rV9Anw0yyD0E3sFjguIUKyE1rI=w114-h114-n-k-no",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23dadce0' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23dadce0' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Nacho Autos",
    rating: 5.0,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
    stars: "(21)",
    businessAge: "29 years in business",
    openHours: "Open 9AM",
    phoneNumber: "(315) 257-1896",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    profileImage:
      "https://lh5.googleusercontent.com/p/AF1QipOBkvX0ja2s2tSpSapZKXTQIX0rbrgnu9181XCo=w114-h114-n-k-no",
  },
  {
    title: "Murgado Automotive Group",
    rating: 4.3,
    stars: "(47)",
    openHours: "Open 7AM",
    phoneNumber: "(315) 257-1896",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "In-Store Shopping . Delivery",
    profileImage:
      "https://lh5.googleusercontent.com/p/AF1QipOCinQCsCVU9NCjb_VORc5WI2kJRWQX0b1G38Iu=w114-h114-n-k-no",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const sponsoredAdData = [
  {
    title: "South Florida Honda Dealers - Honda Dealer Near Me",
    description:
      "Get a deal you'll like on a Honda vehicle you'll love when you shop available offers.",
    website: "southflorida.hondadealers.com",
    link: "https://southflorida.hondadealers.com",
  },
  {
    title: "Enterprise Car Sales® - Visit Your Local Dealership",
    description:
      "Find your next car at Enterprise. Quality vehicles with today's top features. Shop...",
    website: "Enterprise Car Sales",
    link: "https://www.enterprisecarsales.com",
  },
];
export const items = [
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 1",
    description: "Item 1 Description",
    showLastSection: "TMS",
  },
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 2",
    description: "Item 2 Description",
  },
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 3",
    description: "Item 3 Description",
  },
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 3",
    description: "Item 3 Description",
  },
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 3",
    description: "Item 3 Description",
  },
];
export const organicData = [
  {
    title: "Braman Miami | New & Used Car Dealerships in Miami, FL",
    website: "Braman Miami",
    link: "https://www.bramanmiami.com",
    description:
      "Where can you find the best new & used cars, including luxury & exotic vehicles? Nowhere Else but Braman Miami. Visit a Braman car dealership in Miami ...",
  },
  {
    title: "Car Dealerships near Miami, FL (Carfax)",
    website: "Carfax",
    link: "https://www.carfax.com › Car Dealers",
    description:
      "Find local dealerships near Miami, FL with verified reviews and shop for used cars. All listings include a free CARFAX Report.",
  },
  {
    title: "Used Car Dealership in Miami, FL (HGreg.com)",
    website: "HGreg.com",
    link: "https://www.hgreg.com › serving-miami-fl",
    description:
      "We have a huge inventory of pristine, late-model used cars that are listed at unbelievable no-haggle prices. Our friendly sales team is non-commissioned, so ...",
  },
  {
    title: "THE BEST 10 Car Dealers in Miami Beach, FL (Yelp)",
    website: "Yelp",
    link: "https://www.yelp.com › ... › Automotive › Car Dealers",
    description:
      "Car dealerships near me from www.yelp.com. Top 10 Best Car Dealers Near Miami Beach, Florida ; Limited Spec Automotive. 5.4 mi. 0.0 (0 reviews) ; Green Light Auto Sales. 4.6 mi. 4.3 (6 reviews) ; Express ...",
  },
  {
    title: "Locate a Dealer (Chevrolet)",
    website: "Chevrolet",
    link: "https://www.chevrolet.com › dealer-locator",
    description:
      "Find a Chevrolet car, truck, and SUV dealership near you: see hours, contact info, and dealer website info at Chevrolet.com.",
  },
  {
    title: "All Dealers in Miami, FL 33130 - Autotrader",
    website: "Autotrader",
    link: "https://www.autotrader.com › car-dealers › miami-fl-3...",
    description:
      "Compare prices from three local dealers. Make, Acura, Alfa Romeo, Audi, BMW, Buick ... Car Dealers by City. Boca Raton · Boynton Beach · Bradenton · Brandon ...",
  },
  {
    title: "Used Cars for Sale in Miami, FL (Cars.com)",
    website: "Cars.com",
    link: "https://www.cars.com › Cars for Sale",
    description:
      "Shop used vehicles in Miami, FL for sale at Cars.com. Research, compare, and save listings, or contact sellers directly from 10,000+ vehicles in Miami, FL.",
  },
  {
    title: "Used Cars for Sale in Miami, FL (Toyota of North Miami)",
    website: "Toyota of North Miami",
    link: "https://www.toyotaofnorthmiami.com › used-vehicles",
    description:
      "For Miami used car dealers you can trust, visit Toyota of North Miami! We're proud to be the go-to used car dealership in Miami with a stock of Toyota SUVs, ...",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7E-R2B68AIQIf8aNkDEasjVEQHyDbU6TuO17gZE9ORZ5u7g7CCyQR&usqp=CAE&s",
  },
  {
    title: "Car Dealerships Near Me (Cars.com)",
    website: "Cars.com",
    link: "https://www.cars.com › dealers › buy",
    description:
      "Dealers near Cheney, KS · Mel Hambelton Ford · Eddy's Chrysler Dodge Jeep RAM · Banter Automotive Group · Hatchett Hyundai West · Davis-Moore Chevrolet · Maize Motors.",
  },
];

export const peopleAskFor = [
  {
    id: 1,
    header: "How much is a used car in Florida?    ",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  },
  {
    id: 2,
    header: "Who owns Braman Motors?    ",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
  },
  {
    id: 3,
    header: "Is it better to buy a car at a local dealership?    ",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
  },
  {
    id: 4,
    header: "Why are car dealerships named after people?    ",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];
export const relatedSearchResults = [
  "dealerships near  Hialeah, FL ",
  "Best car dealerships near me",
  "Used car dealerships near me",
  "car dealerships in miami",
  "Cheap car dealerships near me",
  "best used car dealerships  in miami",
];
