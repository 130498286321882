import React from "react";
import "./MoreBtn.scss";

export const MoreBtn = ({ btnText }) => {
  return (
    <div className="btnContainer">
      <div className="moreBtn">
        <button>{btnText}</button>
        <svg
          width={20}
          fill="#70757a"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
        </svg>
      </div>
    </div>
  );
};
