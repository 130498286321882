import React from "react";
import { LocalPack } from "../../components/LocalPack";
import { SponsoredAds } from "../../components/SponsoredAds";
import { LocalServiceAd } from "../../components/LocalServiceAd";
import { peopleAskFor } from "../../data/PlumberSearchPage";
import "./PlumberSearchPage.scss";
import Footer from "../../components/Footer";
import { MoreBtn } from "../../components/MoreBtn";
import { RelatedSearch } from "../../components/RelatedSearch";
import {
  PPCAds,
  findResult,
  localPack,
  localServiceAds,
  organicResult,
} from "../../data/multipleChoiceQuestion";
import { ROUTE_LINKS } from "../../routeLinks";
import { useParams } from "react-router-dom";
import { PeopleAskFor } from "../../components/PeopleAskFor";
import {
  adCardData,
  locaPackCardData,
  organicData,
  sponsoredAdData,
  relatedSearchResults,
} from "../../data/PlumberSearchPage";
import Nav from "../../components/Nav";
const PlumberSearchPage = () => {
  // const ADTYPE = "Plumbers near me";
  const pageUrl = ROUTE_LINKS.PLUMBERSEARCH;

  // const { type } = useParams();

  // const data = searchPagesData[type];

  // if (!data) return <>No Data.....</>;

  // const {
  //   adCardData,
  //   locaPackCardData,
  //   organicData,
  //   sponsoredAdData,
  //   relatedSearchResults,
  // } = data;

  return (
    <>
      <Nav pageName={"Plumbers near me"} />
      <div className="plumberSearchPage">
        <LocalServiceAd
          showSponsored={true}
          businessName="Plumbers | Miami
        "
          isGoogleGuaranteed={true}
          adCardData={adCardData}
          btnText={"More Plumber"}
          choices={localServiceAds}
          adType={"Local Services Ads"}
          pageUrl={pageUrl}
        />

        {sponsoredAdData.map((ad, index) => (
          <SponsoredAds
            key={index}
            showSponsored={true} // Customize these props as needed
            adData={ad} // Pass the dynamic data as a prop
            choices={PPCAds}
            adType={"PPC Ads"}
            pageUrl={pageUrl}
          />
        ))}
        <LocalPack
          title={"Business"}
          isFlexRow={true}
          hasCustomPosition={true}
          locaPackCardData={locaPackCardData}
          btnText={"More Business"}
          choices={localPack}
          adType={"Local Pack"}
          pageUrl={pageUrl}
        />

        <PeopleAskFor peopleAskFor={peopleAskFor} />
        {/* organic results */}
        {organicData.map((ad, index) => (
          <SponsoredAds
            key={index}
            adData={ad}
            choices={organicResult}
            adType={"Organic"}
            pageUrl={pageUrl}
          />
        ))}
        <RelatedSearch
          data={relatedSearchResults}
          adType={"Related Search"}
          pageUrl={pageUrl}
          choices={findResult}
        />
        <MoreBtn btnText={"More results"} />
      </div>
      <Footer location={"Miami, Florida"} />
    </>
  );
};

export default PlumberSearchPage;
