import React, { useEffect, useState } from "react";
import "./AdPopup.scss";
import getRandomPageURL from "../../utils/getRandomPageURL";
import { useNavigate } from "react-router-dom";

const AdPopup = ({ popupData, compData, adType, onClose, pageUrl }) => {
  const [selectedChoice, setSelectedChoice] = useState("");
  const [formData, setFormData] = useState([]);
  const [lastId, setLastId] = useState(0);
  const [isSubmitted, setSubmitted] = useState(false);
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);
  const [ipAddress, setIPAddress] = useState("");
  const navigate = useNavigate();

  const handleChoiceChange = (choice) => {
    setSelectedChoice(choice);
    setIsCheckboxSelected(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("popup")) {
      onClose();
    }
  };

  const initialize = async () => {
    try {
      const ipAdd = await fetch("https://api.ipify.org?format=json");
      const { ip } = await ipAdd.json();

      if (!ip) {
        throw new Error("Ip not found");
      } else {
        setIPAddress(ip);
        console.log("ip address inside ipadd", ipAddress);
      }

      //

      console.log("ip address", ipAddress);
    } catch (error) {
      console.log("error");
    }
  };
  useEffect(() => {
    initialize();
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedChoice) {
      setIsCheckboxSelected(true);
      return;
    }
    const newId = lastId + 1;
    const newData = {
      id: newId,
      name: compData.title,
      dateAdded: new Date().toISOString(),
      reason: selectedChoice,
      adType: adType,
      ipAddress: ipAddress,
      pageUrl: pageUrl,
    };

    setLastId(newId);
    setFormData((prevData) => [...prevData, newData]);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: newData.name,
        reason: newData.reason,
        adType: newData.adType,
        url: newData.pageUrl,
        ipAddress: newData.ipAddress,
      }),
    };

    fetch(
      `${process.env.REACT_APP_SERVER_URL}/v1/formSubmissions`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          console.log("New data:", newData);

          setSubmitted(true);
        } else {
          console.error("Error sending POST request.");
        }
      })
      .catch((error) => {
        console.error("Error sending POST request:", error);
      });
  };

  const handleOnClose = async () => {
    console.log("on close submission", isSubmitted);
    if (isSubmitted) {
      console.log("inside close function", isSubmitted);

      try {
        const randomPageURL = await getRandomPageURL(); // Use your function to get a random page URL
        console.log("Random Page URL:", randomPageURL); // Log the URL for debugging
        navigate(randomPageURL);
      } catch (error) {
        console.error("Error while getting random page URL:", error);
      }
    }
    onClose();
  };

  return (
    <div className={`popup `}>
      <div className="popupContent">
        <div onClick={handleOnClose} className="fa-times">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="20"
            viewBox="0 0 100 100"
          >
            <line
              x1="10"
              y1="10"
              x2="90"
              y2="90"
              stroke="black"
              strokeWidth="10"
              strokeLinecap="round"
            />
            <line
              x1="90"
              y1="10"
              x2="10"
              y2="90"
              stroke="black"
              strokeWidth="10"
              strokeLinecap="round"
            />
          </svg>
        </div>

        {isSubmitted ? (
          <div className="thank-you-popup appear">
            <div>
              <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="40" fill="#65bd65" />
                <path
                  d="M30 50L45 65L70 40"
                  fill="none"
                  stroke="white"
                  strokeWidth="10"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <h1>Thank you For your feedback!</h1>
          </div>
        ) : (
          <>
            <h2 className="popupTitle">
              Now please can you tell us why you clicked on that specific
              result?
            </h2>

            <form onSubmit={handleSubmit}>
              <ul className="choiceList">
                {popupData.map((choice, index) => (
                  <li key={index} className="listData">
                    <label>
                      <input
                        type="radio"
                        name="choices"
                        value={choice}
                        checked={selectedChoice === choice}
                        onChange={() => handleChoiceChange(choice)}
                      />
                      {choice}
                    </label>
                  </li>
                ))}
              </ul>
              <div className="errorContainer">
                {isCheckboxSelected && (
                  <p className="showError">
                    Please make a selection before submitting.
                  </p>
                )}
                <button className="submitBtn" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default AdPopup;
