import React from "react";
import "./SponsoredTag.scss";
const SponsoredTag = () => {
  return (
    <div>
      <span className="sponsoredTag">Sponsored</span>
    </div>
  );
};

export default SponsoredTag;
