import React from "react";
import "./Footer.scss";
const Footer = ({ location }) => {
  return (
    <div className="footeSection">
      <div className="locationContainer">
        <div>
          <span className="circle"></span>
          <span className="location">{location}</span> -
          <span className="ipAddress">From your IP Address</span> -{" "}
          <span className="updateLocation">Update Location</span>
        </div>
        <div className="options">
          <span className="moreBtn">More Option in</span>
          <span className="setting">Quick setting</span>
          <span className="settingBtn">
            (<i className="fas fa-cog"></i>)
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
