export const adCardData = [
  {
    title: "Roto-Rooter Plumbing & Water Cleanup",
    rating: 4.8,
    stars: "(1.8k)",
    businessAge: "88 years in business",
    openHours: "Open 24/7",
    phoneNumber: "(786) 789-6395",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    isPhone: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Peter's Plumbing INC",
    rating: 4.4,
    stars: "(1.2k)",
    businessAge: "29 years in business",
    openHours: "Open 24/7",
    phoneNumber: "(315) 257-1896",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    isPhone: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const locaPackCardData = [
  {
    title: "Plumbing service | Caleb Plumbing Service | Emergency Plumbing",
    rating: 4.7,
    stars: "(26)",
    businessAge: "3+ years in business",
    openHours: "Open 24 hours",
    phoneNumber: "(786) 961-2999",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Spark Plumbing",
    rating: 4.8,
    stars: "(21)",
    // businessAge: "3+ years in business",
    openHours: "Open 24 hours",
    phoneNumber: "(123) 456-7890",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Sewer Solutions /Pipe lining /Septic",
    rating: 4.8,
    stars: "(88)",
    businessAge: "10+ years in business",
    openHours: "Open 24 hours",
    phoneNumber: "(123) 456-7890",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const sponsoredAdData = [
  {
    title: "Plumbers Near Me - Affordable Plumbing Services",
    description:
      "Efficient sewer pipe repair using advanced technology. Commercial, residential, industrial. Rapid emergency repairs to fix any ",
    website: "bestplumbersnearme-miami.com",
    link: "https://www.bestplumbersnearme-miami.com › plumbers › near_me",
    featuredImage:
      "https://tpc.googlesyndication.com/simgad/5432084365296356111?sqp=-oaymwEKCFwQXCABUAFYAQ&rs=AOga4qlJBekIlvqHwZh8arMUuz272LqQqg",
    icon: "",
  },
  {
    title: "Plumbers Near Me - Same Day Service & Repair",
    website: "https://www.plumberappointment.com/plumbing",
    link: "https://www.bestplumbersnearme-miami.com › plumbers › near_me",
    description:
      "Local 24-Hr Plumbers Near Me. Fast, Same-Day Service - Day or Night. Call Now!",
    services: [
      "Plumbing Repair",
      "Plumbing Services",
      "Toilets",
      "Gas Lines",
      "Clogged Drains",
      "Water Heaters",
    ],
  },
];

export const organicData = [
  {
    title: "THE BEST 10 Plumbing in Miami, FL",
    description:
      "Top 10 Best Plumbing Near Miami, Florida ; Main Plumbing Services. 5.0 mi · 922 SW 3rd St, Ste 4 Miami, FL 33130 ; Mohr Corp Plumbing. 1.1 mi · 3121 SW 64th Ave, ",
    website: "Yelp",
    link: "https://www.yelp.com › Yelp › Home Services › Plumbing",
  },
  {
    title: "Top 10 Best Plumbers in Miami, FL",
    description:
      "Looking for reliable plumbing services in Miami? Look no further than Benjamin Franklin. Our team of experts is here to tackle all your plumbing needs with ...",
    website: "Angi",
    link: "https://www.angi.com",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
    rating: " 4.4 ",
    review: " 19,153 ",
  },
  {
    title: "Miami, FL Plumbers Near Me | 24/7 Emergency Plumbers",
    description:
      "Roto-Rooter is the trusted plumber in Miami, FL providing 24-hour emergency plumbing & drain services. Call Roto-Rooter at (305) 535-2373 for Miami plumbing ...",
    website: "Roto-Rooter",
    link: "https://www.rotorooter.com › Locations › Florida",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
    rating: " 4.8 ",
    review: " 461",
  },
  {
    title: "Miami Plumbers | Miami Emergency Plumbers | Miami 24/7 ...",
    description:
      "Miami 24/7 Plumbing is a company servicing any plumbing needs in Miami. Our Miami Plumbers will make the repair as painless as possible.",
    website: "Miami 24/7 Plumbing",
    link: "https://www.miami247plumbing.com",
  },
  {
    title: "Best 5 Plumbers in Miami, FL",
    website: "This Old House",
    link: "https://www.thisoldhouse.com/plumbing/reviews/plumbers-near-me/florida/miami",
    description:
      "Miami Leak Detection Plumbing · 1031 Redbird Ave Miami Springs, FL 33166 ; The Plumbers · 4639 SW 75th Ave Miami, FL 33155 ; Hernandez Plumbing Co.",
  },
  {
    title: "Best 15 Local Plumbers, Companies & Services in Miami, FL",
    website: "Houzz",
    link: "https://www.houzz.com › ... › Plumbers › Miami / 50 mi",
    description:
      "Local Plumbers, Companies & Services in Miami · Prestige Plumbing Services Inc. Average rating: 5 out of 5 stars5.017 Reviews · Hiring Guide · Dave21 Inc. Average ...",
  },
  {
    title: "Main Plumbing Services - Best Emergency Plumber in Miami ...",
    website: "Main Plumbing Services",
    link: "http://mainplumbingmiami.com",
    description:
      "We are a 24 hour emergency plumbing company in Miami. LOCAL MIAMI PLUMBER LICENSED & INSURED CFC1429312. CALL US NOW! (786) 269-3783.",
  },
  {
    title: "Hernandez Plumbing: Hire the Most Adept Plumber in Miami",
    website: "Hernandez Plumbing",
    link: "https://plumbinginmiamifl.com",
    description:
      "Rely on Hernandez Plumbing to provide you with best plumbers in Miami, FL. We offer emergency services for commercial & residential plumbing ",
  },
  {
    title: "Plumbers in Miami, FL | Rapid-Rooter Plumbing & Drain ...",
    website: "Rapid Rooter Plumbing",
    link: "https://www.rapidrooter.com › Miami Plumber",
    description:
      "Don't Hesitate to Contact Our Expert Miami Plumbers. When you're searching for a plumber near me in Miami, you can stop right here with Rapid Rooter Plumbing & ...",
    rating: " 4.7 ",
    review: " 288 ",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23dadce0' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const sponsoredLast = [
  {
    title: "Same-Day Service Available - Plumber Near Me",
    description:
      "Need Professional Plumbing Solutions? Our Sarasota Experts Are Here to Help! Get Expert Plumbing Services from Sarasota Professionals You Can Trust. Upfront & Honest Pricing. Immediate Responses. Licensed & Insured. Services: Drain Services, Plumbing Services. ",
    website: "arcticair4me.com",
    link: "http://www.arcticair4me.com",
  },
];
export const relatedSearchResults = [
  "Plumbers near Hialeah, FL",
  "cheap plumber miami",
  "Cheap plumbers near me",
  "emergency plumber near me",
  "Best plumbers near me",
  "plumbers kendall",
];

export const peopleAskFor = [
  {
    id: 1,
    header: "How to get a Plumber?",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  },
  {
    id: 2,
    header: "Where does it come from?",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
  },
  {
    id: 3,
    header: "Why do we use it?",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
  },
  {
    id: 4,
    header: "Where can I get some?",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];

// export const searchPagesData = {
//   plumber: {
//     adCardData: [
//       {
//         title: "Roto-Rooter Plumbing & Water Cleanup",
//         rating: 4.8,
//         stars: "(1.8k)",
//         businessAge: "88 years in business",
//         openHours: "Open 24/7",
//         phoneNumber: "(786) 789-6395",
//         address: "123 Main St, City, State 12345",
//         description: "We provide top-quality plumbing services...",
//         // Add more data properties as needed
//         website: "https://google.com",
//         directionsLink: "https://google.example.com",
//         additionalInfo: "Licensed and insured plumbing company",
//         isPhone: true,
//         starImage: [
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//         ],
//       },
//       {
//         title: "Peter's Plumbing INC",
//         rating: 4.4,
//         stars: "(1.2k)",
//         businessAge: "29 years in business",
//         openHours: "Open 24/7",
//         phoneNumber: "(315) 257-1896",
//         address: "123 Main St, City, State 12345",
//         description: "We provide top-quality plumbing services...",
//         // Add more data properties as needed
//         website: "https://google.com",
//         directionsLink: "https://google.example.com",
//         additionalInfo: "Licensed and insured plumbing company",
//         isPhone: true,
//         starImage: [
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//         ],
//       },
//     ],
//     locaPackCardData: [
//       {
//         title: "Plumbing service | Caleb Plumbing Service | Emergency Plumbing",
//         rating: 4.7,
//         stars: "(26)",
//         businessAge: "3+ years in business",
//         openHours: "Open 24 hours",
//         phoneNumber: "(786) 961-2999",
//         address: "123 Main St, City, State 12345",
//         description: "We provide top-quality plumbing services...",
//         // Add more data properties as needed
//         website: "https://google.com",
//         directionsLink: "https://google.example.com",
//         additionalInfo: "Licensed and insured plumbing company",
//         starImage: [
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//         ],
//       },
//       {
//         title: "Spark Plumbing",
//         rating: 4.8,
//         stars: "(21)",
//         // businessAge: "3+ years in business",
//         openHours: "Open 24 hours",
//         phoneNumber: "(123) 456-7890",
//         address: "123 Main St, City, State 12345",
//         description: "We provide top-quality plumbing services...",
//         // Add more data properties as needed
//         website: "https://google.com",
//         directionsLink: "https://google.example.com",
//         additionalInfo: "Licensed and insured plumbing company",
//         starImage: [
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//         ],
//       },
//       {
//         title: "Sewer Solutions /Pipe lining /Septic",
//         rating: 4.8,
//         stars: "(88)",
//         businessAge: "10+ years in business",
//         openHours: "Open 24 hours",
//         phoneNumber: "(123) 456-7890",
//         address: "123 Main St, City, State 12345",
//         description: "We provide top-quality plumbing services...",
//         // Add more data properties as needed
//         website: "https://google.com",
//         directionsLink: "https://google.example.com",
//         additionalInfo: "Licensed and insured plumbing company",
//         starImage: [
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//         ],
//       },
//     ],
//     sponsoredAdData: [
//       {
//         title: "Plumbers Near Me - Affordable Plumbing Services",
//         description:
//           "Efficient sewer pipe repair using advanced technology. Commercial, residential, industrial. Rapid emergency repairs to fix any ",
//         website: "bestplumbersnearme-miami.com",
//         link: "https://www.bestplumbersnearme-miami.com › plumbers › near_me",
//         featuredImage:
//           "https://tpc.googlesyndication.com/simgad/5432084365296356111?sqp=-oaymwEKCFwQXCABUAFYAQ&rs=AOga4qlJBekIlvqHwZh8arMUuz272LqQqg",
//         icon: "",
//       },
//       {
//         title: "Plumbers Near Me - Same Day Service & Repair",
//         website: "https://www.plumberappointment.com/plumbing",
//         description:
//           "Local 24-Hr Plumbers Near Me. Fast, Same-Day Service - Day or Night. Call Now!",
//         services: [
//           "Plumbing Repair",
//           "Plumbing Services",
//           "Toilets",
//           "Gas Lines",
//           "Clogged Drains",
//           "Water Heaters",
//         ],
//       },
//       {
//         title: "Same Day Plumbing Service - Servicing All of Miami Area",
//         description:
//           "$40 Off Plumbing Service - Service - Miami-Dade & Broward County FL Areas. Locally Owned & Operated",
//         website: "miami-rooter.com",
//         link: "https://www.miami-rooter.com",
//       },
//     ],
//     organicData: [
//       {
//         title: "THE BEST 10 Plumbing in Miami, FL",
//         description:
//           "Top 10 Best Plumbing Near Miami, Florida ; Main Plumbing Services. 5.0 mi · 922 SW 3rd St, Ste 4 Miami, FL 33130 ; Mohr Corp Plumbing. 1.1 mi · 3121 SW 64th Ave, ",
//         website: "Yelp",
//         link: "https://www.yelp.com › Yelp › Home Services › Plumbing",
//       },
//       {
//         title: "Top 10 Best Plumbers in Miami, FL",
//         description:
//           "Looking for reliable plumbing services in Miami? Look no further than Benjamin Franklin. Our team of experts is here to tackle all your plumbing needs with ...",
//         website: "Angi",
//         link: "https://www.angi.com",
//         starImage: [
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//         ],
//         rating: " 4.4 ",
//         review: " 19,153 ",
//       },
//       {
//         title: "Miami, FL Plumbers Near Me | 24/7 Emergency Plumbers",
//         description:
//           "Roto-Rooter is the trusted plumber in Miami, FL providing 24-hour emergency plumbing & drain services. Call Roto-Rooter at (305) 535-2373 for Miami plumbing ...",
//         website: "Roto-Rooter",
//         link: "https://www.rotorooter.com › Locations › Florida",
//         starImage: [
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//         ],
//         rating: " 4.8 ",
//         review: " 461",
//       },
//       {
//         title: "Miami Plumbers | Miami Emergency Plumbers | Miami 24/7 ...",
//         description:
//           "Miami 24/7 Plumbing is a company servicing any plumbing needs in Miami. Our Miami Plumbers will make the repair as painless as possible.",
//         website: "Miami 24/7 Plumbing",
//         link: "https://www.miami247plumbing.com",
//       },
//       {
//         title: "Best 5 Plumbers in Miami, FL",
//         website: "This Old House",
//         link: "https://www.thisoldhouse.com/plumbing/reviews/plumbers-near-me/florida/miami",
//         description:
//           "Miami Leak Detection Plumbing · 1031 Redbird Ave Miami Springs, FL 33166 ; The Plumbers · 4639 SW 75th Ave Miami, FL 33155 ; Hernandez Plumbing Co.",
//       },
//       {
//         title: "Best 15 Local Plumbers, Companies & Services in Miami, FL",
//         website: "Houzz",
//         link: "https://www.houzz.com › ... › Plumbers › Miami / 50 mi",
//         description:
//           "Local Plumbers, Companies & Services in Miami · Prestige Plumbing Services Inc. Average rating: 5 out of 5 stars5.017 Reviews · Hiring Guide · Dave21 Inc. Average ...",
//       },
//       {
//         title: "Main Plumbing Services - Best Emergency Plumber in Miami ...",
//         website: "Main Plumbing Services",
//         link: "http://mainplumbingmiami.com",
//         description:
//           "We are a 24 hour emergency plumbing company in Miami. LOCAL MIAMI PLUMBER LICENSED & INSURED CFC1429312. CALL US NOW! (786) 269-3783.",
//       },
//       {
//         title: "Hernandez Plumbing: Hire the Most Adept Plumber in Miami",
//         website: "Hernandez Plumbing",
//         link: "https://plumbinginmiamifl.com",
//         description:
//           "Rely on Hernandez Plumbing to provide you with best plumbers in Miami, FL. We offer emergency services for commercial & residential plumbing ",
//       },
//       {
//         title: "Plumbers in Miami, FL | Rapid-Rooter Plumbing & Drain ...",
//         website: "Rapid Rooter Plumbing",
//         link: "https://www.rapidrooter.com › Miami Plumber",
//         description:
//           "Don't Hesitate to Contact Our Expert Miami Plumbers. When you're searching for a plumber near me in Miami, you can stop right here with Rapid Rooter Plumbing & ...",
//         rating: " 4.7 ",
//         review: " 288 ",
//         starImage: [
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//           "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23dadce0' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
//         ],
//       },
//     ],
//     relatedSearchResults: [
//       "Plumbers near Hialeah, FL",
//       "cheap plumber miami",
//       "Cheap plumbers near me",
//       "emergency plumber near me",
//       "Best plumbers near me",
//       "plumbers kendall",
//       "Plumbers near me prices",
//       "miami 24/7 plumbing",
//     ],
//     peopleAskFor: [
//       {
//         id: 1,
//         header: "What is Lorem Ipsum?",
//         text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
//       },
//       {
//         id: 2,
//         header: "Where does it come from?",
//         text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
//       },
//       {
//         id: 3,
//         header: "Why do we use it?",
//         text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
//       },
//       {
//         id: 4,
//         header: "Where can I get some?",
//         text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
//       },
//     ],
//   },
//   dentist: {
//     adCardData,
//     locaPackCardData,
//     sponsoredAdData,
//     organicData,
//     relatedSearchResults,
//     peopleAskFor,
//   },
// };
