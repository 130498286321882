export const PPCAds = [
  "It was a sponsored result",
  "Prominent position in the results",
  "Appealing business name",
  "Their logo caught my eye",
  "Nice photo/image in ad",
  "Appealing description of business",
  "Don’t know / unsure",
];
export const localServiceAds = [
  "Offered a Google Guarantee",
  "Appealing business name",
  "Position in the results",
  "Positive review star rating",
  "Don’t know / unsure",
];
export const localPack = [
  "Position in the results",
  "Positive review star rating",
  "High number of reviews",
  "The business is local",
  "Advert in the pack of results",
  "Appealing business name",
  "Appealing description text",
  "Don’t know / unsure",
];
export const organicResult = [
  "Appealing business name",
  "Appealing description of business",
  "Had positive star ratings",
  "It was a genuine local business",
  "It was a directory site",
  "Nice photo/image next to the result",
  "Don’t know / unsure",
];
export const findResult = [
  "I prefer to look for information on other sites",
  "I liked the logos of the websites displayed",
  "Position in the results",
  "Don’t know / unsure",
];
