import React, { useEffect, useState } from "react";
import "./index.scss";
import SponsoredTag from "../SponsoredTag";
import AdPopup from "../AdPopup";
export const SponsoredAds = ({
  showSponsored,
  adData,
  choices,
  adType,
  pageUrl,
}) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen((prev) => !prev);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <>
      <div className="sponsoredSearchResult" onClick={openPopup}>
        {showSponsored && <SponsoredTag />}

        <div className="webInfoContainer">
          <span className="icon">
            {adData.icon}
            <i className="fas fa-globe-asia"></i>
          </span>
          <div className="webDetail">
            <span className="webDetail__title">{adData.website}</span>
            <div className="webDetail__linkContainer">
              <span className="webDetail__link">{adData.link}</span>
              <span className="webDetail__moreBtn">
                <svg width={3} height={12}>
                  {/* Your SVG code here */}
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className="searchResultDetailContainer">
          <div className="searchResultDetail">
            <h3 className="searchResultDetail__title">{adData.title}</h3>
            <p className="searchResultDetail__des">{adData.description}</p>
            {adData.descriptionList && (
              <ul className="descriptionList">
                {adData.descriptionList.map((list, index) => (
                  <li key={index}>
                    {" "}
                    <h3 className="listTitle">{list.title}</h3>
                    <p className="listDes">{list.description}</p>
                  </li>
                ))}
              </ul>
            )}
            {adData.location && (
              <span className="location">{adData.location}</span>
            )}
            {adData.deal && (
              <div className="deals">
                <span>Deal:</span>
                {adData.deal}
              </div>
            )}
            {adData.services && (
              <ul className="services">
                {adData.services.map((service, index) => (
                  <li key={index}>{service}</li>
                ))}
              </ul>
            )}
          </div>
          {adData.featuredImage && (
            <img src={adData.featuredImage} alt="featuredImage" />
          )}
        </div>
        <div className="ratingContainer">
          {adData.icon && (
            <div
              className="itemIcon"
              dangerouslySetInnerHTML={{ __html: adData.icon }}
            />
          )}
          {adData.starImage &&
            adData.starImage.map((image, index) => (
              <span className="stars" key={index}>
                <img src={image} alt={`Star ${index + 1}`} />
              </span>
            ))}
          {adData.rating && (
            <div className="rating"> Rating: {adData.rating} . </div>
          )}
          {adData.review && (
            <div className="review-count">{adData.review} reviews</div>
          )}
        </div>
        {adData.isCall && (
          <div className="callUs">
            <span className="phone">
              <i className="fas fa-phone-alt"></i>
              <span>Call Us</span>
            </span>
          </div>
        )}
      </div>
      {isPopupOpen && (
        <AdPopup
          popupData={choices}
          compData={adData}
          adType={adType}
          onClose={closePopup}
          pageUrl={pageUrl}
        />
      )}
    </>
  );
};
