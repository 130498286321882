import React from "react";
import "./LocalServiceAd.scss";
import SponsoredTag from "../SponsoredTag";
import LocaPackCard from "../LocalPackCard";

export const LocalServiceAd = ({
  showSponsored,
  businessName,
  isGoogleGuaranteed,
  adCardData,
  btnText,
  choices,
  adType,
  pageUrl,
}) => {
  return (
    <div className="localServiceAdContainer">
      <div className="sponsoredInfo">
        {showSponsored && <SponsoredTag />}
        <span className="businessName">{businessName}</span>
        <span className="menuBtn">
          <i className="fas fa-ellipsis-v"></i>
        </span>
      </div>
      {isGoogleGuaranteed && (
        <div className="googleGuaranteed">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 100 100"
          >
            <circle cx="50" cy="50" r="40" fill="#00A86B" />
            <path
              d="M20 50L40 70L80 30"
              fill="none"
              stroke="#FFF"
              strokeWidth="8"
              strokeLinecap="round"
            />
          </svg>

          <span className="googleGuaranteedText">Google guaranteed</span>
        </div>
      )}
      {adCardData.map((cardData, index) => (
        <LocaPackCard
          key={index}
          showBorder={true}
          direction={false}
          addEllipsis={true}
          data={cardData}
          choices={choices}
          adType={adType}
          pageUrl={pageUrl}
        />
      ))}

      <div className="moreBtn">
        <button>{btnText}</button>
        <i className="fas fa-arrow-right"></i>
      </div>
    </div>
  );
};
