// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  width: 70%; }

.cardHeader {
  display: flex;
  align-items: center;
  gap: 2rem;
  border-bottom: 1px solid #dadce0;
  padding-bottom: 2rem; }
  .cardHeader h1 {
    font-size: 2.2rem;
    font-weight: 400; }

.feedback {
  align-items: center;
  color: #70757a;
  display: flex;
  font-size: 1.2rem;
  font-style: normal;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  padding-bottom: 1rem;
  position: relative;
  display: flex;
  justify-content: flex-end; }
  .feedback::before {
    content: "";
    position: absolute;
    height: 1px;
    width: 90%;
    left: 0%;
    background-color: #dadce0; }
`, "",{"version":3,"sources":["webpack://./src/components/PeopleAskFor/PeopleAskFor.scss"],"names":[],"mappings":"AAAA;EACE,UAAU,EAAA;;AAEZ;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,gCAAgC;EAChC,oBAAoB,EAAA;EALtB;IAOI,iBAAiB;IACjB,gBAAgB,EAAA;;AAGpB;EACE,mBAAmB;EACnB,cAAc;EACd,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,mBAAmB;EACnB,oBAAoB;EACpB,kBAAkB;EAClB,aAAa;EACb,yBAAyB,EAAA;EAZ3B;IAcI,WAAW;IACX,kBAAkB;IAClB,WAAW;IACX,UAAU;IACV,QAAQ;IACR,yBAAyB,EAAA","sourcesContent":[".card {\n  width: 70%;\n}\n.cardHeader {\n  display: flex;\n  align-items: center;\n  gap: 2rem;\n  border-bottom: 1px solid #dadce0;\n  padding-bottom: 2rem;\n  h1 {\n    font-size: 2.2rem;\n    font-weight: 400;\n  }\n}\n.feedback {\n  align-items: center;\n  color: #70757a;\n  display: flex;\n  font-size: 1.2rem;\n  font-style: normal;\n  margin: 0;\n  padding: 0;\n  white-space: nowrap;\n  padding-bottom: 1rem;\n  position: relative;\n  display: flex;\n  justify-content: flex-end;\n  &::before {\n    content: \"\";\n    position: absolute;\n    height: 1px;\n    width: 90%;\n    left: 0%;\n    background-color: #dadce0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
