// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 2.4rem;
  padding: 0.8rem 2rem;
  width: 69rem;
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  margin: 1rem 0;
  height: 3rem; }
  .search-bar:hover {
    box-shadow: 0 2px 8px 1px rgba(64, 60, 67, 0.24); }
  .search-bar .search-icon {
    padding: 0.8rem 1.5rem;
    color: #4285f4; }
  .search-bar .voice-icon {
    padding: 0.8rem 1.5rem; }
  .search-bar .closeBtn {
    padding: 0 1.5rem;
    border-right: 1px solid gray; }
  .search-bar input {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    font-size: 1.6rem; }
    .search-bar input::placeholder {
      color: black; }
  .search-bar i {
    font-size: 1.6rem; }
`, "",{"version":3,"sources":["webpack://./src/components/SearchBar/SearchBar.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,6BAA6B;EAC7B,qBAAqB;EACrB,oBAAoB;EACpB,YAAY;EACZ,gDAAgD;EAChD,cAAc;EACd,YAAY,EAAA;EAVd;IAYI,gDAAgD,EAAA;EAZpD;IAeI,sBAAsB;IACtB,cAAc,EAAA;EAhBlB;IAmBI,sBAAsB,EAAA;EAnB1B;IAsBI,iBAAiB;IACjB,4BAA4B,EAAA;EAvBhC;IA0BI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,iBAAiB,EAAA;IA9BrB;MAgCM,YAAY,EAAA;EAhClB;IAqCI,iBAAiB,EAAA","sourcesContent":[".search-bar {\n  display: flex;\n  align-items: center;\n  background-color: transparent;\n  border: 1px solid transparent;\n  border-radius: 2.4rem;\n  padding: 0.8rem 2rem;\n  width: 69rem;\n  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);\n  margin: 1rem 0;\n  height: 3rem;\n  &:hover {\n    box-shadow: 0 2px 8px 1px rgba(64, 60, 67, 0.24);\n  }\n  .search-icon {\n    padding: 0.8rem 1.5rem;\n    color: #4285f4;\n  }\n  .voice-icon {\n    padding: 0.8rem 1.5rem;\n  }\n  .closeBtn {\n    padding: 0 1.5rem;\n    border-right: 1px solid gray;\n  }\n  input {\n    border: none;\n    outline: none;\n    background: transparent;\n    width: 100%;\n    font-size: 1.6rem;\n    &::placeholder {\n      color: black;\n    }\n  }\n\n  i {\n    font-size: 1.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
