// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #202124;
  font-size: 28px;
  line-height: 33px;
  font-weight: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/Heading/Heading.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,mBAAmB,EAAA","sourcesContent":[".title {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  color: #202124;\n  font-size: 28px;\n  line-height: 33px;\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
