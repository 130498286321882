import React, { useState } from "react";
import "./CustomDropdown.scss";

function CustomDropdown({ title, options }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    console.log("Dropdown toggle clicked"); // Add this line
    setIsDropdownOpen(!isDropdownOpen);
  };
  // const hours = [
  //   "Any time",
  //   "Open now",
  //   "Open 24 hours",
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ];
  // const Rating = [5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1];
  return (
    <div className={`dropdown ${isDropdownOpen ? "open" : ""}`}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <i className="fas fa-caret-down"></i>
        {title}
      </button>
      <ul className="dropdown-menu">
        {options.map((option, index) => (
          <li key={index}>{option}</li>
        ))}
      </ul>
    </div>
  );
}

export default CustomDropdown;
