import React, { useState, useEffect } from "react";
import "./Nav.scss";
import googleImage from "../../assets/images/google.png";
import SearchBar from "../SearchBar";

const Nav = ({ pageName }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClasses = `navbar ${isScrolled ? "with-shadow" : ""}`;

  return (
    <div className={navbarClasses}>
      <img src={googleImage} alt="Google" />
      <SearchBar pageName={pageName} />
    </div>
  );
};

export default Nav;
