export const adCardData = [
  {
    title: "Barnett Capital Advisors",
    rating: 5.0,
    stars: "(4)",
    tag: "Financial Planner",
    businessAge: "12+ years in business",
    openHours: "Opens at 9AM ET",
    phoneNumber: "(877) 868-0532",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "In-Store Shopping . Delivery",
    isPhone: true,
    isMessage: true,
    profileImage:
      "https://lh3.googleusercontent.com/DgCHHV0lat9q-srg0CGsNja_ykYEPKTF_Yg_yscJDJXhNaDytLQoXAhTNUXl_Qk=sc0x00ffffff-w100-h100-fcrop64=1,00001835ffffc311-rj",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Kovar Wealth Management",
    rating: 4.3,
    stars: "(6)",
    tag: "Financial Planner",
    businessAge: "8+ years in business    ",
    openHours: "Open 24/7",
    phoneNumber: "(737) 210-4126    ",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    isPhone: true,
    isMessage: true,
    profileImage:
      "https://lh3.googleusercontent.com/DgCHHV0lat9q-srg0CGsNja_ykYEPKTF_Yg_yscJDJXhNaDytLQoXAhTNUXl_Qk=sc0x00ffffff-w100-h100-fcrop64=1,00001835ffffc311-rj",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const locaPackCardData = [
  {
    title: "MANNA WEALTH MANAGEMENT - Miami Financi",
    rating: 5,
    stars: "(50)",
    openHours: "Open 6AM",
    tag: "Financial Planner",
    businessAge: "60+ years in Business",
    phoneNumber: "(786) 789-6395",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "Onsite services·Online appointments",
    address: "777 Brickell Ave Suite 708, Miami, FL 33131",
    isWebsite: true,
    isDirection: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Wealth USA, LLC",
    rating: 5,
    stars: "(9)",
    openHours: "Open 9AM",
    tag: "Financial Planner",
    phoneNumber: "(315) 257-1896",
    businessAge: "55+ years in Business",
    address: "1221 Brickell Ave Suite 900 · (305.....",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    isWebsite: true,
    isDirection: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "MANNA WEALTH MANAGEMENT - Florida Fin..",
    rating: 5,
    stars: "(68)",
    openHours: "Open 6AM",
    tag: "Financial Planner",
    phoneNumber: "(315) 257-1896",
    businessAge: "68+ years in Business",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "In-Store Shopping . Delivery",
    isWebsite: true,
    isDirection: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const sponsoredAdData = [
  {
    title: "A Financial Advisor Near You - Financial Advisors (Edward Jones®)",
    website: "Edward Jones®",
    link: "https://www.edwardjones.com › financial › advisor",
    description:
      "Get financial guidance tailored to your goals. Contact your local financial advisor today!",
    location: "Plumber · Miami · Open 24 hours",
    deal: " Up to $75 off Plumbing & Drain",
    // descriptionList: [
    //   {
    //     title: "Title 1",
    //     description: "Description for Title 1",
    //   },
    //   {
    //     title: "Title 2",
    //     description: "Description for Title 2",
    //   },
    //   {
    //     title: "Title 3",
    //     description: "Description for Title 3",
    //   },
    // ],
  },
  {
    title: "How to Find a Financial Advisor Near You (NerdWallet)",
    website: "NerdWallet",
    link: "https://www.nerdwallet.com › Investing",
    description:
      "Aug 11, 2023 — 1. Use an online financial advisor matching service · 2. Check the CFP Board website · 3. Look into professional finance advisor organizations · 4. ‎Zoe Financial Review 2023 · ‎Facet Wealth · ‎Harness Wealth Review 2023",
  },
];

export const organicData = [
  {
    title: "Top 10 Best Financial Advisor in Miami, FL - October 2023 (Yelp)",
    website: "Yelp",
    link: "https://www.yelp.com › ... › Financial Advisor",
    description:
      "Top 10 Best Financial Advisor Near Miami, Florida ; Modern Money Advisor. 9.4 mi · 429 Lenox Ave, Miami Beach, FL 33139 ; Coral Gables Trust. 1.7 mi · 255 Alhambra ...",
  },
  {
    title: "How to Find a Financial Advisor Near You (NerdWallet)",
    website: "NerdWallet",
    link: "https://www.nerdwallet.com › Investing",
    description:
      "Aug 11, 2023 — 1. Use an online financial advisor matching service · 2. Check the CFP Board website · 3. Look into professional finance advisor organizations · 4. ‎Zoe Financial Review 2023 · ‎Facet Wealth · ‎Harness Wealth Review 2023",
  },
  {
    title: "Find a financial planner near you (Thumbtack)",
    website: "Thumbtack",
    link: "https://www.thumbtack.com › Business",
    description:
      "Here is the definitive list of financial planners near your location as rated by your neighborhood community. Want to see who made the cut?",
  },
  {
    title:
      "Find an Advisor (National Association of Personal Financial Advisors)",
    website: "National Association of Personal Financial Advisors",
    link: "https://www.napfa.org › find-an-advisor",
    description:
      "Find a fee-only financial advisor near you with the Find an Advisor tool from the National Association of Personal Financial Advisors.",
  },
  {
    title: "Financial Planners in Miami, Florida (PlannerSearch)",
    website: "PlannerSearch",
    link: "https://www.plannersearch.org › miami-fl",
    description:
      "PlannerSearch is a directory of financial service professionals in The Financial Planning Association. Find a financial planner in Miami, Florida today!",
  },
  {
    title: "Top 10 Financial Advisors in Miami, FL (SmartAsset)",
    website: "SmartAsset",
    link: "https://smartasset.com › financial-advisor › miami-fl-t...",
    description:
      "May 25, 2023 — Looking for a financial advisor in Miami? We round up the top firms in the city, along with their fees, services, investment strategies and ...",
  },
  {
    title: "How To Find a Financial Advisor Near You (Forbes)",
    website: "Forbes",
    link: "https://www.forbes.com › advisor › investing › financi...",
    description:
      "Jun 8, 2023 — The internet is a great tool for finding almost anything, financial advisors included. But when you type “financial advisors near me” into ...",
  },
  {
    title: "Hire Top Financial Advisors in Miami, FL ❘ LinkedIn (LinkedIn)",
    website: "LinkedIn",
    link: "https://www.linkedin.com › services › financial-advisors-...",
    description:
      "Leverage the power of Linkedin, the world's largest professional network, to find & hire top Financial Advisors in Miami, FL.",
  },
  {
    title: "Find a CFP® Professional (Let's Make a Plan)",
    website: "Let's Make a Plan",
    link: "https://www.letsmakeaplan.org › find-a-cfp-professional",
    description:
      "The Find a CFP® Professional search tool is powered by Google Maps to identify advisors near your specified location. Results are randomized, then tailored to ...",
  },
];

export const peopleAskFor = [
  {
    id: 1,
    header: "Is it worth paying for a financial planner?    ",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  },
  {
    id: 2,
    header:
      "Is it better to have a financial advisor or financial planner?    ",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
  },
  {
    id: 3,
    header: "What percentage do most financial planners charge?    ",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
  },
  {
    id: 4,
    header: "How much does a financial plan cost CFP?    ",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];
export const relatedSearchResults = [
  "Financial planner near Miami, FL",
  "hourly fee-only  financial planner near me",
  "Financial planner near Hialeah, FL",
  "Independent financial planner near me",
  "certified financial planner near me",
  "fiduciary financial planner near me",
];
