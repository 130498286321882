// App.js
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import getRandomPageURL from "./utils/getRandomPageURL";
import PlumberSearchPage from "./pages/PlumberSearchPage";
import GymSearchPage from "./pages/GymSearch";
import CarDealershipPage from "./pages/CarDealershipPage";
import FinancialPlannersPage from "./pages/FinancialPlannersPage";
import GardenerSearchPage from "./pages/GardenerSearchPage";
import DentistSearchPage from "./pages/DentistSearchPage";
import VeterinarianSearchPage from "./pages/VeterinarianSearchPage";
import { ROUTE_LINKS } from "./routeLinks";
import AllCaughtUpPage from "./pages/AllCaughtUpPage";
function App() {
  const [randomPageURL, setRandomPageURL] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!randomPageURL) {
      // Call getRandomPageURL within the useEffect
      getRandomPageURL().then((url) => {
        console.log("Random Page URL:", url); // Log the URL for debugging
        setRandomPageURL(url);
        navigate(url);
      });
    }
  }, [randomPageURL, navigate]);

  return (
    <div className="App">
      <Routes>
        <Route
          path={ROUTE_LINKS.PLUMBERSEARCH}
          element={<PlumberSearchPage />}
        />
        <Route
          path={ROUTE_LINKS.CARDEALERSHIP}
          element={<CarDealershipPage />}
        />
        <Route path={ROUTE_LINKS.GYMSEARCH} element={<GymSearchPage />} />
        <Route
          path={ROUTE_LINKS.FINANCIALPLANNERS}
          element={<FinancialPlannersPage />}
        />
        <Route
          path={ROUTE_LINKS.GARDENERSEARCH}
          element={<GardenerSearchPage />}
        />
        <Route
          path={ROUTE_LINKS.DENTISTSEARCH}
          element={<DentistSearchPage />}
        />
        <Route
          path={ROUTE_LINKS.VETERINARIANSEARCH}
          element={<VeterinarianSearchPage />}
        />
        <Route path="/thankyou" element={<AllCaughtUpPage />} />
      </Routes>
    </div>
  );
}

export default App;
