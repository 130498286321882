export const adCardData = [
  {
    title: "SWEAT440 Brickell",
    rating: 4.8,
    stars: "(1.8k)",
    businessAge: "1 years in business",
    openHours: "Open 24/7",
    phoneNumber: "(786) 789-6395",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
    isPhone: true,
  },
  {
    title: "Fitness Center",
    rating: 4.4,
    stars: "(1.2k)",
    businessAge: "2 years in business",
    openHours: "Open 24/7",
    phoneNumber: "(315) 257-1896",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
    isPhone: true,
  },
];

export const locaPackCardData = [
  {
    title: "SWEAT440 Brickell",
    rating: 4.8,
    stars: "(219)",
    businessAge: "3+ years in business",
    openHours: "Open 6AM",
    phoneNumber: "(786) 961-2999",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    isWebsite: true,
    isDirection: true,
    onlineService: "Onsite Service",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "BE21 FITNESS",
    rating: 4.9,
    stars: "(55)",
    // businessAge: "3+ years in business",
    openHours: "Open 6:30AM",
    phoneNumber: "(123) 456-7890",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    isWebsite: true,
    isDirection: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Fitbox Method",
    rating: 5,
    stars: "(46)",
    openHours: "Open 24 hours",
    phoneNumber: "(123) 456-7890",
    address: "123 Main St, City, State 12345",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
    isDirection: true,
  },
];
export const sponsoredAdData = [
  {
    title: "Health/Fitness Clubs & Gyms in Miami",
    description:
      "Get a deal you'll like on a Honda vehicle you'll love when you shop available offers.",
    website: "Health/Fitness Clubs & Gyms in Miami",
    link: "https://www.tripadvisor.com › ... › Things to Do in Miami",
  },
  {
    title: "Miami's Best Gyms For Every Budget",
    description:
      "$40 Off Plumbing Service - Service - Miami-Dade & Broward County FL Areas. Locally Owned & Operated",
    website: "miami-rooter.com",
    link: "https://www.miami-rooter.com",
  },
];
export const items = [
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 1",
    description: "Item 1 Description",
    showLastSection: "TMS",
  },
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 2",
    description: "Item 2 Description",
  },
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 3",
    description: "Item 3 Description",
  },
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 3",
    description: "Item 3 Description",
  },
  {
    icon: `<i class="fas fa-globe-asia"></i>`,
    title: "Item 3",
    description: "Item 3 Description",
  },
];
export const organicData = [
  {
    title: "THE BEST 10 Plumbing in Miami, FL",
    description:
      "Top 10 Best Plumbing Near Miami, Florida ; Main Plumbing Services. 5.0 mi · 922 SW 3rd St, Ste 4 Miami, FL 33130 ; Mohr Corp Plumbing. 1.1 mi · 3121 SW 64th Ave, ",
    website: "Yelp",
    link: "https://www.yelp.com  › ... › Fitness & Instruction › Gyms",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSB1kcjr-wQZ-G-pbmuts11TBw5qDmcs0pJAFL4ziNo-gq6OrSwgn&usqp=CAE&s",
  },
  {
    title: "15 Best Gyms in Miami, Florida - Fitness Centers Near You ...",
    description:
      "15 Best Gyms in Miami, Florida · UHealth Fitness & Wellness Center · Movement Lab · Box IQ Fitness Knowledge · Underline Fit · Body by Loleta · Miami Iron Gym · Legacy ...",
    website: "RitFit",
    link: "https://www.ritfitsports.com",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRtSB1kcjr-wQZ-G-pbmuts11TBw5qDmcs0pJAFL4ziNo-gq6OrSwgn&usqp=CAE&s",
  },
  {
    title: "The Ten Best Gyms in Miami",
    description:
      "Dec 14, 2021 — The Ten Best Gyms in Miami · Anatomy · Barry's Bootcamp · The Edge Rock Gym · Equinox · Fitness District · GluteHouse · Gymage · LegacyFit.",
    website: "Miami New Times",
    link: "https://www.miaminewtimes.com › arts › the-ten-best-...",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZIvGYa2QNk7uBh1WYhJg8CB_Np42-anzOjxH5Wdna-zpb7pAs5CNZ&usqp=CAE&s",
  },
  {
    title: "Miami",
    description:
      "24 Hour Fitness is a fitness center with locations in Miami. Find your nearest gym and get started on your fitness journey today!",
    website: "24 Hour Fitness",
    link: "https://www.24hourfitness.com › gyms › miami-fl",
  },
  {
    title: "Steel Supplements",
    website: "This Old House",
    link: "https://steelsupplements.com › The STEEL Library",
    description:
      "Apr 4, 2023 — 1. Best Luxury Gym: Anatomy Fitness · 2. Best 24/7 Gym: South Beach Fitness · 3. Best Boutique Gym: Barry's Bootcamp · 4. Best Holistic Gym: Morphē ...",
  },
  {
    title: "Health/Fitness Clubs & Gyms in Miami",
    website: " Tripadvisor",
    link: "https://www.tripadvisor.com › ... › Things to Do in Miami ",
    description:
      "These places are best for health/fitness clubs & gyms in Miami: mySpa · DermaGym · Grusky Chiropractic Center · Angels 5 Body · The Edge Rock Gym - Miami · See ...",
  },
  {
    title: "Anytime Fitness",
    website: "Main Plumbing Services",
    link: "https://www.anytimefitness.com › find-gym",
    description:
      "Find an Anytime Fitness gym near you. Members enjoy 24/7 access to thousands of convenient and inviting fitness center locations worldwide.",
  },
  {
    title: "Stay Fit 305",
    website: "Hernandez Plumbing",
    link: "https://www.stayfit305.com › news › 14-must-try-mia...",
    description:
      "Feb 14, 2022 — Looking for the best gym in Miami? We'll let you decide on who takes the top spot, but for now, try one of these Miami gyms in 2022.",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQosQ1xVH3bhPi7h2fCmwh6SJv1txmAW2lb_11tbYwiQAX2MCQlvojd&usqp=CAE&s",
  },
  {
    title: "Time Out",
    website: "Rapid Rooter Plumbing",
    link: "https://www.timeout.com › miami › things-to-do › bes...",
    description:
      "Jan 15, 2021 — The best gyms in Miami offer a mix of cycling classes, low-impact workouts and HIIT training to get you in top shape.",
    rating: " 4.7 ",
    review: " 288 ",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];

export const peopleAskFor = [
  {
    id: 1,
    header: "Who has the cheapest gym membership?    ",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  },
  {
    id: 2,
    header: "Why is Planet Fitness so cheap?    ",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
  },
  {
    id: 3,
    header: "Where do celebrities workout in Miami?    ",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
  },
  {
    id: 4,
    header: "How much is Gymage Miami?    ",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];
export const relatedSearchResults = [
  "Gyms near Hialeah, FL ",
  "Women's gyms near me",
  "Cheap gyms near me",
  "planet fitness near me",
  "Gyms in miami",
  "24 hour fitness",
];
