import React from "react";

import "./LocalPack.scss";
import CustomDropdown from "../CustomDropdown";
import LocaPackCard from "../LocalPackCard";
import { Heading } from "../Heading";
import SponsoredTag from "../SponsoredTag";

export const LocalPack = ({
  title,
  isFlexRow,
  hasCustomPosition,
  isBusiness,
  showSponsored,
  businessName,
  isGoogleGuaranteed,
  btnText,
  locaPackCardData,
  isPlace,
  choices,
  adType,
  pageUrl,
}) => {
  const localPackClasses = ["LocalPackSection"];
  if (isFlexRow) {
    localPackClasses.push("flex-row");
  }
  const hours = [
    "Any time",
    "Open now",
    "Open 24 hours",
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const Rating = [5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1];
  return (
    <div className={localPackClasses.join(" ")}>
      <div className="header">
        <div className="titleContainer">
          <Heading title={title} />
          <span className="menuBtn">
            <i className="fas fa-ellipsis-v"></i>
          </span>
        </div>
      </div>
      <div className="details">
        {/* <iframe
          class="map"
          src="https://www.google.com/maps/d/embed?mid=1xmEOp534MOV24OzCYrcLPCvCfDifD2Sb&iwloc=nofullscreen"
          width="100%"
          height="480"
        ></iframe>
         */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d114964.54550197783!2d-80.31186136896606!3d25.78238429202308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9b0a20ec8c111%3A0xff96f271ddad4f65!2sMiami%2C%20FL%2C%20USA!5e0!3m2!1sen!2s!4v1698409205337!5m2!1sen!2s"
          width="600"
          height="450"
          // style="border:0;"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>

        <div
          className={`dropdownData ${
            hasCustomPosition ? "customPosition" : ""
          }`}
        >
          <CustomDropdown title="Rating" options={Rating} />
          <CustomDropdown title="Hours" options={hours} />
        </div>

        <div className="cardContainer">
          {isBusiness && (
            <div className="sponsoredInfo">
              {showSponsored && <SponsoredTag />}
              <span className="businessName">{businessName}</span>
              <span className="menuBtn">
                <i className="fas fa-ellipsis-v"></i>
              </span>
            </div>
          )}
          {isGoogleGuaranteed && (
            <div className="googleGuaranteed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 100 100"
              >
                <circle cx="50" cy="50" r="40" fill="#00A86B" />
                <path
                  d="M20 50L40 70L80 30"
                  fill="none"
                  stroke="#FFF"
                  strokeWidth="8"
                  strokeLinecap="round"
                />
              </svg>

              <span className="googleGuaranteedText">Google guaranteed</span>
            </div>
          )}
          {locaPackCardData.map((cardData, index) => (
            <LocaPackCard
              key={index}
              data={cardData}
              isPlace={isPlace}
              choices={choices}
              adType={adType}
              pageUrl={pageUrl}
            />
          ))}
          <div className="moreBtn">
            <button>{btnText}</button>
            <i className="fas fa-arrow-right"></i>
          </div>
        </div>
      </div>
    </div>
  );
};
