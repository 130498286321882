// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RelatedSearchContainer {
  padding: 4rem 0 2rem;
  width: 60%;
  cursor: pointer; }
  .RelatedSearchContainer__header {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2rem; }
    .RelatedSearchContainer__header h1 {
      font-size: 2.2rem;
      font-weight: 400; }
  .RelatedSearchContainer .list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    font-size: 1.6rem;
    gap: 1rem;
    margin: 0; }
    .RelatedSearchContainer .list .SearchContainer {
      display: flex;
      align-items: center;
      gap: 2rem;
      align-items: center;
      background-color: #dedede;
      border-radius: 100px;
      box-sizing: border-box;
      display: flex;
      max-height: none;
      min-height: 48px;
      padding-left: 17px;
      padding-right: 17px;
      position: relative;
      width: 49%; }
`, "",{"version":3,"sources":["webpack://./src/components/RelatedSearch/RelatedSearch.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,UAAU;EACV,eAAe,EAAA;EACf;IACE,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,oBAAoB,EAAA;IAJrB;MAMG,iBAAiB;MACjB,gBAAgB,EAAA;EAXtB;IAgBI,aAAa;IACb,eAAe;IACf,UAAU;IACV,gBAAgB;IAEhB,iBAAiB;IACjB,SAAS;IACT,SAAS,EAAA;IAvBb;MAyBM,aAAa;MACb,mBAAmB;MACnB,SAAS;MACT,mBAAmB;MACnB,yBAAyB;MACzB,oBAAoB;MACpB,sBAAsB;MACtB,aAAa;MACb,gBAAgB;MAChB,gBAAgB;MAChB,kBAAkB;MAClB,mBAAmB;MACnB,kBAAkB;MAClB,UAAU,EAAA","sourcesContent":[".RelatedSearchContainer {\n  padding: 4rem 0 2rem;\n  width: 60%;\n  cursor: pointer;\n  &__header {\n    display: flex;\n    align-items: center;\n    gap: 2rem;\n    padding-bottom: 2rem;\n    h1 {\n      font-size: 2.2rem;\n      font-weight: 400;\n    }\n  }\n\n  .list {\n    display: flex;\n    flex-wrap: wrap;\n    padding: 0;\n    list-style: none;\n    // display: flex;\n    font-size: 1.6rem;\n    gap: 1rem;\n    margin: 0;\n    .SearchContainer {\n      display: flex;\n      align-items: center;\n      gap: 2rem;\n      align-items: center;\n      background-color: #dedede;\n      border-radius: 100px;\n      box-sizing: border-box;\n      display: flex;\n      max-height: none;\n      min-height: 48px;\n      padding-left: 17px;\n      padding-right: 17px;\n      position: relative;\n      width: 49%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
