export const adCardData = [
  {
    title: "Miami Pet Clinic",
    rating: 4.6,
    stars: "(251)",
    openHours: "Open at 8AM",
    businessAge: "2 years in business",

    phoneNumber: "(855) 269-0942 · Offers discounts",
    address: "Serves Miami",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    isPhone: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Miami Veterinary Specialists ",
    rating: 5.0,
    stars: "(208)",
    businessAge: "3 years in business",
    openHours: "Open at 9AM",
    phoneNumber: "(305) 665-4448",
    address: "Serves Miami",
    isPhone: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const locaPackCardData = [
  {
    title: "Brickell Bay Animal Hospital",
    rating: 4.5,
    stars: "(301)",
    openHours: "Open 8AM",
    tag: "Animal Hospital",
    businessAge: "10+ years in business",
    phoneNumber: " (305) 373-9699",
    address: " 940 Brickell Bay Dr · (786) 231-1111",
    description: "We provide top-quality plumbing services...",
    isWebsite: true,
    isDirection: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Bella Veterinary Services",
    rating: 4.9,
    stars: "(37)",
    openHours: "Open 9AM",
    tag: "Veterinarian",
    phoneNumber: "(786) 566-2199",
    address: "AStar Miami Institute Four Seasons Miami Hotel, 1441 Brick",
    description: "We provide top-quality plumbing services...",
    isWebsite: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Wags Animal Hospital",
    rating: 4.4,
    stars: "(49)",
    openHours: "Open 9AM",
    tag: "Veterinarian",
    businessAge: "3+ years in business",
    phoneNumber: " 691 Brickell Key Dr",
    address: "40 SW 13th St Suite 103 · (305) 222-9899",
    description: "We provide top-quality plumbing services...",
    isDirection: true,
    isWebsite: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56' fill='none'><path d='M51.219 22.5633C51.0843 22.1496 50.8366 21.7818 50.5038 21.5014C50.1711 21.221 49.7666 21.0393 49.336 20.9767L36.0383 19.0447L30.093 6.99532C29.8904 6.62072 29.5869 6.31029 29.2171 6.09917C28.8472 5.88804 28.4256 5.78464 28 5.80066V43.7873L39.893 50.0383C40.2783 50.2409 40.7127 50.3317 41.1469 50.3003C41.5811 50.269 41.9979 50.1167 42.3502 49.8609C42.7024 49.605 42.976 49.2557 43.1401 48.8525C43.3042 48.4492 43.3522 48.0081 43.2787 47.579L41.0083 34.335L50.6287 24.955C50.9404 24.6511 51.1609 24.2661 51.2652 23.8434C51.3695 23.4208 51.3535 22.9774 51.219 22.5633Z' fill='%23BEBEBE' fill-opacity='0.1'/><path d='M25.907 6.99533L19.9617 19.0447L6.66399 20.9767C6.23303 21.0392 5.82818 21.2212 5.49524 21.5019C5.1623 21.7826 4.91457 22.1509 4.78007 22.5651C4.64556 22.9793 4.62966 23.4229 4.73415 23.8456C4.83864 24.2684 5.05935 24.6535 5.37133 24.9573L14.9917 34.335L12.7213 47.579C12.6481 48.0078 12.6962 48.4485 12.8602 48.8514C13.0242 49.2543 13.2976 49.6033 13.6494 49.8591C14.0013 50.1149 14.4176 50.2672 14.8515 50.2988C15.2853 50.3305 15.7194 50.2403 16.1047 50.0383L28 43.785V5.84033C27.1693 5.84267 26.3597 6.23 25.907 6.99533Z' fill='%23FFB545'/></svg>",
    ],
  },
];
export const sponsoredAdData = [
  {
    title: "Miami Animal Clinic: Veterinarian in Miami, FL",
    website: "Miami Animal Clinic",
    link: "https://www.miamianimalclinic.net",
    description:
      "Miami Animal Clinic is a skilled Veterinarian in Miami, FL. Accepting new appointments. Call today or request an appointment on our website.",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyFD18TG147-cPyzbsrq-eCvEsiUfIF46IWnzyWqAgCVEj65Yp4Eby&usqp=CAE&s",
  },
  {
    title: "Brickell Bay Animal Hospital: Veterinarian in Miami, FL",
    website: "Brickell Bay Animal Hospital",
    link: "https://brickellbayanimalhospital.com",
    description:
      "(786) 231-1111 | 940 Brickell Bay Drive, Miami, FL 33131. Brickell Bay Animal Hospital. Menu. Home · About Us · Our Team ...",
  },
];

export const organicData = [
  {
    title: "THE BEST 10 Veterinarians in Miami, FL",
    website: "Yelp",
    link: "https://www.yelp.com",
    description:
      "Top 10 Best Veterinarians Near Miami, Florida ; Carballo Animal Clinic. 2.9 mi · (40 reviews) · 8468 SW 8th St, Miami, FL 33144. (305) 552-9121 ; Sunset Animal ...",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqQ6H4l8qlsXVWLtXe4Mg4BxZUUPddiBBxrjisL7iggR9E1DYHq9FV&usqp=CAE&s",
  },
  {
    title:
      "Miami Pet Clinic: Vet in Miami, Little Havana, Allapattah, Coral ...",
    website: "Miami Pet Clinic",
    link: "https://miamipetclinic.com",
    description:
      "Miami Pet Clinic provides veterinary care for pets in Little Havana, East Coral Gables, Allapattah, Silver Bluff Estates, Coral Groves, Coconut Grove and ...",
  },
  {
    title: "THE BEST 10 Veterinarians near Miami, FL 33132",
    website: "Yelp",
    link: "https://www.yelp.com",
    description:
      "The Best 10 Veterinarians near Miami, FL 33132 ; Brickell Bay Animal Hospital. 1.8 mi. 4.4 (162 reviews) · 940 Brickell Bay Dr, Miami, FL 33131. (786) 231-1111.",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqQ6H4l8qlsXVWLtXe4Mg4BxZUUPddiBBxrjisL7iggR9E1DYHq9FV&usqp=CAE&s",
  },

  {
    title: "20 Best Miami Veterinarians",
    website: "Expertise.com",
    link: "https://www.expertise.com",
    description:
      "My Vet at Home is one of the 20 Best Veterinarians in Miami. Hand picked by an independent editorial team and updated for 2023.",
  },
  {
    title: "Emergency Vet in Miami, FL",
    website: "Veterinary Emergency Group",
    link: "https://veterinaryemergencygroup.com",
    description:
      "VEG is an emergency vet in Miami, FL providing trusted care and customer service to the community. If you have a question, call now to speak to a vet!",
  },

  {
    title: "The Best Veterinarians In Miami",
    website: "Miami Pet Concierge",
    link: "https://miamipetconcierge.com",
    description:
      "How to choose the right veterinaraian for your pets and our list of the Best Veterinarians in Miaimi.",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSq4xY2sY4jEVZ7PaUPUZLckH57fXs-Af2Qjxyq6fQhkCHEW8Y5Nf_d&usqp=CAE&s",
  },
  {
    title: "Free Mobile Veterinary Clinic to help pet owners in ...",
    website: "Miami-Dade County (.gov)",
    link: "https://www.miamidade.gov",
    description:
      "Oct 2, 2023 — The Wellness on Wheels (WOW) free mobile veterinary clinic is a partnership between Miami-Dade Animal Services and the Friends of Miami ...",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTTEXfX9U_O2JKXBS6myGDdoxey4nZ0sabjvl8oCevB8NI7RzcHXX3&usqp=CAE&s",
  },
  {
    title: "Miami Animal Hospital: Veterinarian in Coral Gables, FL",
    website: "Miami Animal Hospital",
    link: "https://miamianimalhospital.com",
    description:
      "Miami Animal Hospital provides comprehensive veterinary care and urgent care to pets in Coral Gables, FL. Call us or book an appointment online today.",
  },
  {
    title: "Find A Vet Near You",
    website: "Pawlicy Advisor",
    link: "https://www.pawlicy.com",
    description:
      "Find a veterinarian near you, and save. Search Vets. Top veterinarians. Top ... Vets Near Me · Pet Care Library · Charity To Help Afford Vet Care. OUR PARTNERS.",
  },
];

export const peopleAskFor = [
  {
    id: 1,
    header: "Why is seeing a vet so expensive?    ",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  },
  {
    id: 2,
    header: "Is a vet and a veterinarian the same thing?    ",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
  },
  {
    id: 3,
    header: "Are veterinarians trusted?    ",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
  },
  {
    id: 4,
    header: "What animals do veterinarians treat?    ",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];
export const relatedSearchResults = [
  "Veterinarian near Hialeah, FL",
  "Best veterinarian near me",
  "Veterinarian near me for dogs",
  "Free veterinarian near me",
  "Low cost veterinarian near me",
  "low-cost  veterinarian miami",
];
