// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  top: -1px;
  z-index: 11;
  background: white;
  padding: 0.5rem 3rem;
  margin-bottom: 3rem; }
  .navbar img {
    width: 9.2rem;
    height: 3rem;
    padding-right: 5.5rem; }

.navbar.with-shadow {
  box-shadow: 0 1px 6px 0px rgba(32, 33, 36, 0.16);
  transition: all; }
`, "",{"version":3,"sources":["webpack://./src/components/Nav/Nav.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,gBAAgB;EAChB,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,oBAAoB;EACpB,mBAAmB,EAAA;EATrB;IAYI,aAAa;IACb,YAAY;IACZ,qBAAqB,EAAA;;AAGzB;EACE,gDAAgD;EAChD,eAAe,EAAA","sourcesContent":[".navbar {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  position: sticky;\n  top: -1px;\n  z-index: 11;\n  background: white;\n  padding: 0.5rem 3rem;\n  margin-bottom: 3rem;\n\n  img {\n    width: 9.2rem;\n    height: 3rem;\n    padding-right: 5.5rem;\n  }\n}\n.navbar.with-shadow {\n  box-shadow: 0 1px 6px 0px rgba(32, 33, 36, 0.16);\n  transition: all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
