export const adCardData = [
  {
    title: "LawnStarter - Miami",
    guarenteedTag: true,
    rating: 4.2,
    stars: "(22)",
    businessAge: "25 years in business",
    openHours: "Open until 8:30PM",
    tag: "Landscraper",
    phoneNumber: "(866) 865-5176    ",
    address: "Serve Miami",
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: " Provides: Garden decorations",
    isPhone: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56' fill='none'><path d='M51.219 22.5633C51.0843 22.1496 50.8366 21.7818 50.5038 21.5014C50.1711 21.221 49.7666 21.0393 49.336 20.9767L36.0383 19.0447L30.093 6.99532C29.8904 6.62072 29.5869 6.31029 29.2171 6.09917C28.8472 5.88804 28.4256 5.78464 28 5.80066V43.7873L39.893 50.0383C40.2783 50.2409 40.7127 50.3317 41.1469 50.3003C41.5811 50.269 41.9979 50.1167 42.3502 49.8609C42.7024 49.605 42.976 49.2557 43.1401 48.8525C43.3042 48.4492 43.3522 48.0081 43.2787 47.579L41.0083 34.335L50.6287 24.955C50.9404 24.6511 51.1609 24.2661 51.2652 23.8434C51.3695 23.4208 51.3535 22.9774 51.219 22.5633Z' fill='%23BEBEBE' fill-opacity='0.1'/><path d='M25.907 6.99533L19.9617 19.0447L6.66399 20.9767C6.23303 21.0392 5.82818 21.2212 5.49524 21.5019C5.1623 21.7826 4.91457 22.1509 4.78007 22.5651C4.64556 22.9793 4.62966 23.4229 4.73415 23.8456C4.83864 24.2684 5.05935 24.6535 5.37133 24.9573L14.9917 34.335L12.7213 47.579C12.6481 48.0078 12.6962 48.4485 12.8602 48.8514C13.0242 49.2543 13.2976 49.6033 13.6494 49.8591C14.0013 50.1149 14.4176 50.2672 14.8515 50.2988C15.2853 50.3305 15.7194 50.2403 16.1047 50.0383L28 43.785V5.84033C27.1693 5.84267 26.3597 6.23 25.907 6.99533Z' fill='%23fbbc04'/></svg>",
    ],
  },
  {
    title: "Land Multiservice Corp",
    rating: 4.9,
    stars: "(38)",
    tag: "Landscraper",
    businessAge: "20 years in business",
    openHours: "Open 24/7",
    phoneNumber: "(786) 255-0761",
    address: "Serve Miami",
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    isPhone: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const locaPackCardData = [
  {
    title: "AS4Less Landscaping",
    rating: 4.6,
    stars: "(53)",
    tag: "Landcaper",
    businessAge: "7+ years in business",
    openHours: "Open 7AM",
    phoneNumber: "(305) 316-3884",
    address: "  Miami, FL ",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "Onsite services·Online estimates",
    isWebsite: true,
    isDirection: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Nacho Autos",
    rating: 5.0,
    stars: "(24)",
    tag: "Lawn care service",
    openHours: "Open 8AM",
    phoneNumber: "(305) 930-1932",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    reviewText: "Very thankful for how Garden of Eden helped us fix our yard.",
    isWebsite: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "APLICANO LANDSCAPING.LLC",
    rating: 5,
    stars: "(10)",
    openHours: "Open 7AM",
    tag: "Gardener",
    businessAge: "5+ years in business",
    phoneNumber: "(786) 263-1145",
    address: "123 Main St, City, State 12345",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "Onsite services·Online estimates",
    isWebsite: true,
    isDirection: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const sponsoredAdData = [
  {
    title: "South Florida Honda Dealers - Honda Dealer Near Me",
    description:
      "Get a deal you'll like on a Honda vehicle you'll love when you shop available offers.",
    website: "southflorida.hondadealers.com",
    link: "https://southflorida.hondadealers.com",
  },
  {
    title: "Enterprise Car Sales® - Visit Your Local Dealership",
    description:
      "Find your next car at Enterprise. Quality vehicles with today's top features. Shop...",
    website: "Enterprise Car Sales",
    link: "https://www.enterprisecarsales.com",
  },
];

export const organicData = [
  {
    title: "THE BEST 10 Gardeners in MIAMI, FL",
    website: "Yelp",
    link: "https://www.yelp.com › ... › Home Services › Gardeners",
    description:
      "Best Gardeners in Miami, FL - JC&J Multiservices, TLC of South Florida, Rapid 1 Tree Service, Simply Landscapes Miami, Lozan Landscaper's, Conde Landscaping ...",
  },
  {
    title: "Best Gardener Service in Miami - Easy Quote Online",
    website: "Lawn Love",
    link: "https://lawnlove.com › ... › Miami lawn care service",
    description:
      "Lawn care made easy! We offer the #1 rated gardener in Miami. Call or visit our site for a free and instant quote for your specific property.",
    rating: "4.8",
    reviews: "97 reviews",
    priceRange: "$20-45",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56' fill='none'><path d='M51.219 22.5633C51.0843 22.1496 50.8366 21.7818 50.5038 21.5014C50.1711 21.221 49.7666 21.0393 49.336 20.9767L36.0383 19.0447L30.093 6.99532C29.8904 6.62072 29.5869 6.31029 29.2171 6.09917C28.8472 5.88804 28.4256 5.78464 28 5.80066V43.7873L39.893 50.0383C40.2783 50.2409 40.7127 50.3317 41.1469 50.3003C41.5811 50.269 41.9979 50.1167 42.3502 49.8609C42.7024 49.605 42.976 49.2557 43.1401 48.8525C43.3042 48.4492 43.3522 48.0081 43.2787 47.579L41.0083 34.335L50.6287 24.955C50.9404 24.6511 51.1609 24.2661 51.2652 23.8434C51.3695 23.4208 51.3535 22.9774 51.219 22.5633Z' fill='%23BEBEBE' fill-opacity='0.1'/><path d='M25.907 6.99533L19.9617 19.0447L6.66399 20.9767C6.23303 21.0392 5.82818 21.2212 5.49524 21.5019C5.1623 21.7826 4.91457 22.1509 4.78007 22.5651C4.64556 22.9793 4.62966 23.4229 4.73415 23.8456C4.83864 24.2684 5.05935 24.6535 5.37133 24.9573L14.9917 34.335L12.7213 47.579C12.6481 48.0078 12.6962 48.4485 12.8602 48.8514C13.0242 49.2543 13.2976 49.6033 13.6494 49.8591C14.0013 50.1149 14.4176 50.2672 14.8515 50.2988C15.2853 50.3305 15.7194 50.2403 16.1047 50.0383L28 43.785V5.84033C27.1693 5.84267 26.3597 6.23 25.907 6.99533Z' fill='%23fbbc04'/></svg>",
    ],
  },
  {
    title: "Hire a Gardener in Miami, Florida",
    website: "MeetAGardener.com",
    link: "https://meetagardener.com › Gardeners › USA › Florida",
    description:
      "Instant access to Miami, Florida Gardeners for hire. Post your Job to find local Gardeners that match your needs!",
  },
  {
    title: "Best 15 Gardeners & Lawn Care Companies in Miami, FL",
    website: "Houzz",
    link: "https://www.houzz.com › ... › Miami / 50 mi",
    description:
      "Search 203 Miami gardeners & lawn care companies to find the best gardener or lawn care service for your project. See the top reviewed local gardeners.",
  },
  {
    title: "Top 10 Gardeners Near Me | Free Grades & Reviews ...",
    website: "Angi",
    link: "https://www.angi.com › Services Near Me",
    description:
      "The best landscape gardeners near you can be found on this local list. In your area Angi has local grades and reviews for FREE - make the best choice.",
  },
  {
    title: "The 10 Best Gardening Services Near Me (with Free Quotes)",
    website: "HomeGuide",
    link: "https://homeguide.com › gardeners",
    description:
      "Here are the 10 best gardening services near you rated by your local neighborhood community. Want to see the top 10?",
    reviews: "3 reviews",
  },
  {
    title: "Find Local Gardening Services | Hire a Gardener Near You",
    website: "Bark.com",
    link: "https://www.bark.com › ... › Gardening & Landscaping",
    description:
      "Find professional gardening services near you with Bark. From turfing to weeding and everything in between, hire a gardener in your local area.",
    rating: "4.8",
    reviews: "42,624 reviews",
    priceRange: "$55 - $90 (Avg: $70)",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56' fill='none'><path d='M51.219 22.5633C51.0843 22.1496 50.8366 21.7818 50.5038 21.5014C50.1711 21.221 49.7666 21.0393 49.336 20.9767L36.0383 19.0447L30.093 6.99532C29.8904 6.62072 29.5869 6.31029 29.2171 6.09917C28.8472 5.88804 28.4256 5.78464 28 5.80066V43.7873L39.893 50.0383C40.2783 50.2409 40.7127 50.3317 41.1469 50.3003C41.5811 50.269 41.9979 50.1167 42.3502 49.8609C42.7024 49.605 42.976 49.2557 43.1401 48.8525C43.3042 48.4492 43.3522 48.0081 43.2787 47.579L41.0083 34.335L50.6287 24.955C50.9404 24.6511 51.1609 24.2661 51.2652 23.8434C51.3695 23.4208 51.3535 22.9774 51.219 22.5633Z' fill='%23BEBEBE' fill-opacity='0.1'/><path d='M25.907 6.99533L19.9617 19.0447L6.66399 20.9767C6.23303 21.0392 5.82818 21.2212 5.49524 21.5019C5.1623 21.7826 4.91457 22.1509 4.78007 22.5651C4.64556 22.9793 4.62966 23.4229 4.73415 23.8456C4.83864 24.2684 5.05935 24.6535 5.37133 24.9573L14.9917 34.335L12.7213 47.579C12.6481 48.0078 12.6962 48.4485 12.8602 48.8514C13.0242 49.2543 13.2976 49.6033 13.6494 49.8591C14.0013 50.1149 14.4176 50.2672 14.8515 50.2988C15.2853 50.3305 15.7194 50.2403 16.1047 50.0383L28 43.785V5.84033C27.1693 5.84267 26.3597 6.23 25.907 6.99533Z' fill='%23fbbc04'/></svg>",
    ],
  },
  {
    title: "Gardening Services Near Me | Home & Small Spaces",
    website: "Thumbtack",
    link: "https://www.thumbtack.com › Home Remodeling",
    description:
      "Browse the top-rated gardeners and gardening companies near you, and compare services, reviews and prices. Finding and hiring a gardener has never been easier.",
  },
  {
    title: "Best 23 Landscape Specialists Near Me",
    website: "HomeAdvisor",
    link: "https://www.homeadvisor.com › ... › Near Me",
    description:
      "From planning and planting to watering and weeding, finding an experienced landscape gardener near you can ensure your flowers and plants.",
  },
];

export const peopleAskFor = [
  {
    id: 1,
    header: "How do I hire a local gardener?    ",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  },
  {
    id: 2,
    header: "What does a good gardener do?      ",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
  },
  {
    id: 3,
    header: "What is all needed for gardening?    ",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
  },
  {
    id: 4,
    header: " Can you make a living as a gardener?     ",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];
export const relatedSearchResults = [
  "Garderner near Miami, FL",
  "Gardener near me prices",
  "Gardener near Hialeah, FL",
  "Best gardener near me",
  "Cheap gardener near me",
  "Small gardener near me",
];
