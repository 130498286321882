// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnContainer {
  padding-top: 5rem; }
  .btnContainer .moreBtn {
    gap: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: -2.9rem;
    margin: 0 0 0 13%;
    font-size: 14px;
    font-family: Roboto, arial, sans-serif;
    font-weight: 400;
    width: 100%;
    max-width: 300px;
    height: 30px;
    border-radius: 18px;
    background-color: #dedede;
    top: -1.3rem;
    color: #202124;
    position: relative;
    cursor: pointer; }
    .btnContainer .moreBtn button {
      background-color: transparent;
      border: none;
      font-size: 1.4rem; }
    .btnContainer .moreBtn i {
      color: #70757a; }
    .btnContainer .moreBtn::before {
      position: absolute;
      content: "";
      width: 200%;
      height: 1px;
      background-color: #dedede;
      top: 50%;
      z-index: -1; }
`, "",{"version":3,"sources":["webpack://./src/components/MoreBtn/MoreBtn.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAA;EADnB;IAGI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,aAAa;IACb,iBAAiB;IACjB,eAAe;IACf,sCAAsC;IACtC,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,eAAe,EAAA;IArBnB;MAuBM,6BAA6B;MAC7B,YAAY;MACZ,iBAAiB,EAAA;IAzBvB;MA4BM,cAAc,EAAA;IA5BpB;MA+BM,kBAAkB;MAClB,WAAW;MACX,WAAW;MACX,WAAW;MACX,yBAAyB;MACzB,QAAQ;MACR,WAAW,EAAA","sourcesContent":[".btnContainer {\n  padding-top: 5rem;\n  .moreBtn {\n    gap: 0.2rem;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    left: -2.9rem;\n    margin: 0 0 0 13%;\n    font-size: 14px;\n    font-family: Roboto, arial, sans-serif;\n    font-weight: 400;\n    width: 100%;\n    max-width: 300px;\n    height: 30px;\n    border-radius: 18px;\n    background-color: #dedede;\n    top: -1.3rem;\n    color: #202124;\n    position: relative;\n    cursor: pointer;\n    button {\n      background-color: transparent;\n      border: none;\n      font-size: 1.4rem;\n    }\n    i {\n      color: #70757a;\n    }\n    &::before {\n      position: absolute;\n      content: \"\";\n      width: 200%;\n      height: 1px;\n      background-color: #dedede;\n      top: 50%;\n      z-index: -1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
