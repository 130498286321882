import { ROUTE_LINKS } from "../routeLinks";

const getRandomPageURL = async () => {
  const pages = Object.values(ROUTE_LINKS);
  try {
    const ipAdd = await fetch("https://api.ipify.org?format=json");
    const { ip } = await ipAdd.json();

    if (!ip) {
      throw new Error("IP not found");
    }

    const lookupSnap = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/v1/formSubmissions/lookup`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ipAddress: ip,
        }),
      }
    );

    if (!lookupSnap.ok) {
      throw new Error("Lookup API request failed");
    }

    const dt = await lookupSnap.json();

    if (!dt.success) {
      throw new Error("Lookup API response is not successful");
    }

    // Extract URLs from submissions
    const submittedPages = dt.data.map((submission) => submission.url);

    // Filter out pages that have already been submitted
    const availablePages = pages.filter(
      (page) => !submittedPages.includes(page)
    );

    if (availablePages.length === 0) {
      // All pages have been submitted
      return "/thankyou"; // Replace with your message
    }

    const randomIndex = Math.floor(Math.random() * availablePages.length);
    return availablePages[randomIndex];
  } catch (error) {
    console.log("Error:", error);
    // Handle the error, e.g., return a default page URL
    return pages[0];
  }
};

export default getRandomPageURL;
