import React, { useState } from "react";
import "./PeopleAskFor.scss";
import { AccordionItem } from "../AccordionItem";

export const PeopleAskFor = ({ peopleAskFor }) => {
  const [active, setActive] = useState(null);

  // const handleToggle = (index) => {
  //   if (active === index) {
  //     setActive(null);
  //   } else {
  //     setActive(index);
  //   }
  // };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="cardHeader">
            <h1>People also ask</h1>
            <i className="fas fa-ellipsis-v"></i>
          </div>
          {peopleAskFor.map((faq, index) => {
            return (
              <AccordionItem
                key={index}
                // active={active}
                // handleToggle={handleToggle}
                faq={faq}
              />
            );
          })}
          <p className="feedback">Feedback</p>
        </div>
      </div>
    </>
  );
};
