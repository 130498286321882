import React from "react";
import "./AllCaughtUpPage.scss";

const AllCaughtUpPage = () => {
  return (
    <div className="all-caught-up-page">
      <div className="thank-you-page ">
        <h1 className="thankyouText">Thank You!</h1>
        <p className="feedbackText">
          Thank you for your valuable feedback. You've explored all the
          available pages, and we appreciate your engagement.
        </p>
        <div>
          <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="40" fill="#65bd65" />
            <path
              d="M30 50L45 65L70 40"
              fill="none"
              stroke="white"
              strokeWidth="10"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <h1 className="caughtUpText">You're All Caught Up!</h1>
      </div>
    </div>
  );
};

export default AllCaughtUpPage;
