import React from "react";
import SearchBar from "../../components/SearchBar";
import { LocalPack } from "../../components/LocalPack";
import { SponsoredAds } from "../../components/SponsoredAds";
import { LocalServiceAd } from "../../components/LocalServiceAd";
import "./GardenerSearchPage.scss";
import {
  adCardData,
  locaPackCardData,
  organicData,
  peopleAskFor,
  relatedSearchResults,
  sponsoredAdData,
} from "../../data/GardenerSearchPage";
import { MoreBtn } from "../../components/MoreBtn";
import Footer from "../../components/Footer";
import { PeopleAskFor } from "../../components/PeopleAskFor";
import { RelatedSearch } from "../../components/RelatedSearch";
import {
  PPCAds,
  findResult,
  localPack,
  localServiceAds,
  organicResult,
} from "../../data/multipleChoiceQuestion";
import Nav from "../../components/Nav";
import { ROUTE_LINKS } from "../../routeLinks";

const GardenerSearchPage = () => {
  // const ADTYPE = "Gardener near me";
  const pageUrl = ROUTE_LINKS.GARDENERSEARCH;
  return (
    <>
      <Nav pageName={"Gardener near me"} />
      <div className="gardenerSearchPage">
        <LocalServiceAd
          showSponsored={true}
          businessName="Lawn Care Providers | Miami"
          isGoogleGuaranteed={false}
          adCardData={adCardData}
          btnText={"More Care Providers"}
          choices={localServiceAds}
          adType={"Local Services Ads"}
          pageUrl={pageUrl}
        />
        {sponsoredAdData.map((ad, index) => (
          <SponsoredAds
            key={index}
            showSponsored={true} // Customize these props as needed
            adData={ad} // Pass the dynamic data as a prop
            choices={PPCAds}
            adType={"PPC Ads"}
            pageUrl={pageUrl}
          />
        ))}
        <LocalPack
          title={"Business"}
          isFlexRow={true}
          hasCustomPosition={true}
          locaPackCardData={locaPackCardData}
          btnText={"More Business"}
          choices={localPack}
          adType={"Local Pack"}
          pageUrl={pageUrl}
        />
        <PeopleAskFor peopleAskFor={peopleAskFor} />
        {/* organic results */}
        {organicData.map((ad, index) => (
          <SponsoredAds
            key={index}
            adData={ad}
            choices={organicResult}
            adType={"Organic"}
            pageUrl={pageUrl}
          />
        ))}

        <RelatedSearch
          data={relatedSearchResults}
          adType={"Related Search"}
          pageUrl={pageUrl}
          choices={findResult}
        />

        <MoreBtn btnText={"More results"} />
      </div>
      <Footer location={"Miami, Florida"} />
    </>
  );
};

export default GardenerSearchPage;
