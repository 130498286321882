export const ROUTE_LINKS = {
  // SEARCHPAGE: "/searchPage/:type",
  PLUMBERSEARCH: "/PlumberSearchPage",
  CARDEALERSHIP: "/CarDealershipSearchPage",
  GYMSEARCH: "/GymSearchPage",
  FINANCIALPLANNERS: "/FinancialPlannersPage",
  GARDENERSEARCH: "/GardenerSearchPage",
  DENTISTSEARCH: "/DentistSearchPage",
  VETERINARIANSEARCH: "/VeterinarianSearchPage",
};
