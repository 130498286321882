import React, { useState } from "react";
import "./SearchBar.scss"; // Import your Sass styles

const SearchBar = ({ pageName }) => {
  const [query, setQuery] = useState("");

  const handleSearch = () => {
    // Implement the search functionality here
    console.log("Searching for:", query);
  };

  return (
    <div className="search-bar">
      <input
        type="text"
        placeholder={pageName} // Display page-specific text in the placeholder
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        disabled
      />
    </div>
  );
};

export default SearchBar;
