import React from "react";
import { LocalPack } from "../../components/LocalPack";
import { SponsoredAds } from "../../components/SponsoredAds";
import "./VeterinarianSearchPage.scss";
import {
  adCardData,
  locaPackCardData,
  organicData,
  peopleAskFor,
  relatedSearchResults,
  sponsoredAdData,
} from "../../data/VeterinarianSearchPage";
import { MoreBtn } from "../../components/MoreBtn";
import Footer from "../../components/Footer";
import { PeopleAskFor } from "../../components/PeopleAskFor";
import { RelatedSearch } from "../../components/RelatedSearch";
import {
  PPCAds,
  findResult,
  localServiceAds,
  organicResult,
} from "../../data/multipleChoiceQuestion";
import Nav from "../../components/Nav";
import { LocalServiceAd } from "../../components/LocalServiceAd";
import { ROUTE_LINKS } from "../../routeLinks";

const VeterinarianSearchPage = () => {
  const ADTYPE = "Veterinarian near me";
  const pageUrl = ROUTE_LINKS.VETERINARIANSEARCH;
  return (
    <>
      {" "}
      <Nav pageName={"Veterinarian near me"} />
      <div className="veterinarianSearchPage">
        <LocalServiceAd
          showSponsored={true}
          businessName="Veterinarian | Miami
        "
          isGoogleGuaranteed={true}
          adCardData={adCardData}
          btnText={"More Plumber"}
          choices={localServiceAds}
          adType={"Local Services Ads"}
          pageUrl={pageUrl}
        />
        {sponsoredAdData.map((ad, index) => (
          <SponsoredAds
            key={index}
            showSponsored={true} // Customize these props as needed
            adData={ad} // Pass the dynamic data as a prop
            choices={PPCAds}
            adType={"PPC Ads"}
            pageUrl={pageUrl}
          />
        ))}
        <LocalPack
          title={"Business"}
          isFlexRow={true}
          hasCustomPosition={true}
          locaPackCardData={locaPackCardData}
          btnText={"More business"}
          choices={localServiceAds}
          adType={"Local Pack"}
          pageUrl={pageUrl}
        />
        <PeopleAskFor peopleAskFor={peopleAskFor} />
        {/* organic results */}
        {organicData.map((ad, index) => (
          <SponsoredAds
            key={index}
            adData={ad}
            choices={organicResult}
            adType={"Organic"}
            pageUrl={pageUrl}
          />
        ))}
        <RelatedSearch
          data={relatedSearchResults}
          adType={"Related Search"}
          pageUrl={pageUrl}
          choices={findResult}
        />
        <MoreBtn btnText={"More results"} />
      </div>
      <Footer location={"Miami, Florida"} />
    </>
  );
};

export default VeterinarianSearchPage;
