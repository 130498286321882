// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thank-you-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh; }
  .thank-you-page .thankyouText {
    font-size: 4rem; }
  .thank-you-page .feedbackText {
    font-size: 1.6rem;
    width: 51%;
    margin: 0rem auto 2rem;
    line-height: 2.3rem;
    text-align: center;
    color: #70757a; }
  .thank-you-page .caughtUpText {
    font-size: 2rem; }
`, "",{"version":3,"sources":["webpack://./src/pages/AllCaughtUpPage/AllCaughtUpPage.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,YAAY,EAAA;EALd;IAOI,eAAe,EAAA;EAPnB;IAUI,iBAAiB;IACjB,UAAU;IACV,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc,EAAA;EAflB;IAkBI,eAAe,EAAA","sourcesContent":[".thank-you-page {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 95vh;\n  .thankyouText {\n    font-size: 4rem;\n  }\n  .feedbackText {\n    font-size: 1.6rem;\n    width: 51%;\n    margin: 0rem auto 2rem;\n    line-height: 2.3rem;\n    text-align: center;\n    color: #70757a;\n  }\n  .caughtUpText {\n    font-size: 2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
