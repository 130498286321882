// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footeSection {
  background-color: #f2f2f2;
  line-height: 4rem;
  padding-bottom: 1.2rem;
  font-size: 1.4rem; }
  .footeSection .locationContainer {
    padding-top: 1rem;
    max-width: 80%;
    margin: auto; }
    .footeSection .locationContainer .circle {
      cursor: pointer;
      margin: 4px 4px 9px 0;
      border-radius: 100%;
      display: inline-block;
      height: 10px;
      vertical-align: middle;
      width: 10px;
      background-color: #4d5156; }
    .footeSection .locationContainer .location {
      cursor: pointer;
      color: #4d5156;
      font-weight: bold; }
    .footeSection .locationContainer .ipAddress,
    .footeSection .locationContainer .updateLocation {
      color: #1a0dab;
      cursor: pointer; }
  .footeSection .options .moreBtn {
    color: #4d5156;
    cursor: pointer; }
  .footeSection .options .setting {
    cursor: pointer;
    padding-left: 0.5rem;
    color: #1a0dab;
    font-weight: bold; }
  .footeSection .options .settingBtn {
    padding-left: 0.4rem;
    color: #1a0dab;
    font-weight: bold; }
    .footeSection .options .settingBtn i {
      color: #1a0dab; }
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,sBAAsB;EACtB,iBAAiB,EAAA;EAJnB;IAMI,iBAAiB;IACjB,cAAc;IACd,YAAY,EAAA;IARhB;MAUM,eAAe;MACf,qBAAqB;MACrB,mBAAmB;MACnB,qBAAqB;MACrB,YAAY;MACZ,sBAAsB;MACtB,WAAW;MACX,yBAAyB,EAAA;IAjB/B;MAoBM,eAAe;MACf,cAAc;MACd,iBAAiB,EAAA;IAtBvB;;MA0BM,cAAc;MACd,eAAe,EAAA;EA3BrB;IAgCM,cAAc;IACd,eAAe,EAAA;EAjCrB;IAoCM,eAAe;IACf,oBAAoB;IACpB,cAAc;IACd,iBAAiB,EAAA;EAvCvB;IA0CM,oBAAoB;IACpB,cAAc;IACd,iBAAiB,EAAA;IA5CvB;MA8CQ,cAAc,EAAA","sourcesContent":[".footeSection {\n  background-color: #f2f2f2;\n  line-height: 4rem;\n  padding-bottom: 1.2rem;\n  font-size: 1.4rem;\n  .locationContainer {\n    padding-top: 1rem;\n    max-width: 80%;\n    margin: auto;\n    .circle {\n      cursor: pointer;\n      margin: 4px 4px 9px 0;\n      border-radius: 100%;\n      display: inline-block;\n      height: 10px;\n      vertical-align: middle;\n      width: 10px;\n      background-color: #4d5156;\n    }\n    .location {\n      cursor: pointer;\n      color: #4d5156;\n      font-weight: bold;\n    }\n    .ipAddress,\n    .updateLocation {\n      color: #1a0dab;\n      cursor: pointer;\n    }\n  }\n  .options {\n    .moreBtn {\n      color: #4d5156;\n      cursor: pointer;\n    }\n    .setting {\n      cursor: pointer;\n      padding-left: 0.5rem;\n      color: #1a0dab;\n      font-weight: bold;\n    }\n    .settingBtn {\n      padding-left: 0.4rem;\n      color: #1a0dab;\n      font-weight: bold;\n      i {\n        color: #1a0dab;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
