// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sponsoredTag {
  font-size: 1.4rem;
  background-color: transparent;
  color: #202124;
  font-weight: bold;
  white-space: nowrap; }
  .sponsoredTag.hover-underline:hover {
    text-decoration: underline; }
`, "",{"version":3,"sources":["webpack://./src/components/SponsoredTag/SponsoredTag.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,6BAA6B;EAC7B,cAAc;EACd,iBAAiB;EACjB,mBAAmB,EAAA;EALrB;IAQM,0BAA0B,EAAA","sourcesContent":[".sponsoredTag {\n  font-size: 1.4rem;\n  background-color: transparent;\n  color: #202124;\n  font-weight: bold;\n  white-space: nowrap;\n  &.hover-underline {\n    &:hover {\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
