export const adCardData = [
  {
    title: "Aspen Dental",
    guarenteedTag: true,
    rating: 4.6,
    stars: "(251)",
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
    openHours: "Open at 8AM",
    businessAge: "2 years in business",

    phoneNumber: "(855) 269-0942 · Offers discounts",
    address: "Serves Miami",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "In-Store Shopping . Delivery",
    isPhone: true,
  },
  {
    title: "The Dental Boutique - David Cabanzon, DDS",
    rating: 5.0,
    stars: "(208)",
    businessAge: "3 years in business",
    openHours: "Open at 9AM",
    phoneNumber: "(305) 665-4448",
    address: "Serves Miami",
    isPhone: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const locaPackCardData = [
  {
    title: "HQ Dontics Dental Centre",
    rating: 4.9,
    stars: "(269)",
    openHours: "Open 9AM",
    tag: "Dentist",
    phoneNumber: " (305) 373-9699",
    address: "1060 Brickell Avenue #103/M2",
    description: "We provide top-quality plumbing services...",
    // Add more data properties as needed
    website: "https://google.com",
    directionsLink: "https://google.example.com",
    additionalInfo: "Licensed and insured plumbing company",
    onlineService: "In-Store Shopping . Delivery",
    reviewText: "Book an appointment now and find out for yourself!",
    isWebsite: true,
    isDirection: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
  {
    title: "Dr. Katerina Topol DDS",
    rating: "No reviews",
    openHours: "Open 8AM",
    tag: "Cosmetic Desntist",
    phoneNumber: "(315) 257-1896",
    address: "AStar Miami Institute Four Seasons Miami Hotel, 1441 Brick",
    description: "We provide top-quality plumbing services...",
    isDirection: true,
  },
  {
    title: "My Dentist in Miami",
    rating: 4.4,
    stars: "(49)",
    openHours: "Open 9AM",
    phoneNumber: "(315) 257-1896",
    address: "40 SW 13th St Suite 103 · (305) 222-9899",
    description: "We provide top-quality plumbing services...",
    reviewText:
      "was able to get an emergency same day appointment for a chipped tooth.",
    isDirection: true,
    isWebsite: true,
    starImage: [
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23.44 19'><polygon fill='%23fbbc04' points='10,15.27 16.18,19 14.54,11.97 20,7.24 12.81,6.63 10,0 7.19,6.63 0,7.24 5.46,11.97 3.82,19'/></svg>",
    ],
  },
];
export const sponsoredAdData = [
  {
    title: "Gemini Dental - South Miami - Top-Rated Miami Dentist",
    description:
      "New Patient Special. Take Care Of Your Teeth, Schedule A Dentist Appt. Today. Affordable and quality dental care for individuals and families in Miami. Family Owned & Operated. Accepts PPO Insurance. Se habla español. 0% Financing...",
    website: "info.geminidentalmiami.com",
    link: "https://info.geminidentalmiami.com/south-miami/dentist",
    featuredImage:
      "https://tpc.googlesyndication.com/simgad/5432084365296356111?sqp=-oaymwEKCFwQXCABUAFYAQ&rs=AOga4qlJBekIlvqHwZh8arMUuz272LqQqg",
  },
  {
    title: "Dentist Near Me - Same Day Appointments",
    description:
      "Quality Dentistry Backed By Our Unmatched Patient Care - We Accept Most PPO Insurances.",
    website: "mirodentalcenter.com",
    link: "https://www.mirodentalcenter.com/nearby",
  },
];

export const organicData = [
  {
    title: "Best Dentists Near Me in Miami, FL",
    website: "ZocDoc",
    link: "https://www.zocdoc.com › Dentists",
    description:
      "Find best Dentists in Miami, Florida & make an appointment online instantly! Zocdoc helps you find Dentists in Miami and other locations with verified ...",
  },
  {
    title: "20 Best Dentists in Miami, FL",
    website: "Opencare",
    link: "https://www.opencare.com › dentists › miami-fl",
    description:
      "Find top-rated dentists in Miami that perfectly matches your needs, is nearby, and ready to deliver a great experience.",
  },
  {
    title: "THE BEST 10 Dentists in Miami, FL",
    website: "Yelp",
    link: "https://www.yelp.com › ... › Dentists",
    description:
      "Dentist near me from www.yelp.com Top 10 Best Dentists Near Miami, Florida · A New Smile Dental Center · Randy L Furshman, DDS · Raffaella Armstrong, DMD · Coral Gables Dentistry · Radiant Smiles ...",
    featuredImage:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS_Acr6OAj96KSy2y8ehvO5CYi82e1lp5wW-IgUbW8RESrO5z8tRCNw&usqp=CAE&s",
  },
  {
    title: "Bayfront Dental | Dentist Miami FL",
    website: "Bayfront Dental",
    link: "https://www.bayfrontdental.com",
    description:
      "Dr. David Weiner, Dr. George Gounakis, and Dr. Lauren Trian of Bayfront Dental offer quality dental treatment to patients in Miami, Brickell, Bayside, ...",
  },
  {
    title: "Midtown Dental Miami",
    website: "Midtown Dental Miami",
    link: "https://www.midtowndentalmiami.com",
    description:
      "MIDTOWN DENTAL. We proudly provide quality dental care to the community of Miami, FL and surrounding areas. Our featured services are Dental Implants, Veneers, ...",
  },
  {
    title: "Dentists at Midtown: Family Dental Office in Miami",
    website: "Dentists at Midtown",
    link: "https://www.dentistsatmidtown.com",
    description:
      "Local dental office on N Miami Blvd in Miami, FL. If you need modern dental care, teeth whitening, oral surgery or same-day dental treatment, contact us.",
  },
  {
    title: "Aspen Dental | Find a Dentist Near You for Dental Care",
    website: "Aspen Dental",
    link: "https://www.aspendental.com",
    description:
      "Aspen Dental dentists provide comprehensive & affordable dental services including dentures, dental implants, and dental checkups. Schedule an appointment!",
  },
  {
    title: "Dentist in Miami, FL | Local Dentist Relax and Smile Dental Care",
    website: "Relax and Smile Dental Care",
    link: "https://www.relaxandsmile.com",
    description:
      "Get a healthy, gorgeous smile at Relax and Smile Dental Care from leading Miami dentist Dr. Laura Cabrera. Personalized modern dentistry that is affordable ...",
  },
  {
    title: "Dentist Directory | Delta Dental Providers | Dentist Near Me",
    website: "Delta Dental",
    link: "https://www.deltadental.com › member › find-a-dentist",
    description:
      "Find in-network dentists in your area. Delta Dental has the largest network of dentists nationwide.",
  },
];

export const peopleAskFor = [
  {
    id: 1,
    header: "How do people afford to go to the dentist?    ",
    text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`,
  },
  {
    id: 2,
    header: "What is the difference between a DMD and DDS?    ",
    text: `It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. `,
  },
  {
    id: 3,
    header: "What is the difference between a dentist and an orthodontist? ",
    text: `Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature,`,
  },
  {
    id: 4,
    header: "What is the difference between a doctor and a dentist?    ",
    text: `There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.`,
  },
];
export const relatedSearchResults = [
  "Dentist near Hialeah, FL",
  "affordable dentist miami",
  "dentist miami",
  "best dentist near me",
  "top 10 dentist near me",
  "dentist near me that accepts medicaid",
];
