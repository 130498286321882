import React from "react";
import { LocalPack } from "../../components/LocalPack";
import { SponsoredAds } from "../../components/SponsoredAds";
import "./CarDealershipPage.scss";

import {
  locaPackCardData,
  organicData,
  peopleAskFor,
  relatedSearchResults,
  sponsoredAdData,
  adCardData,
} from "../../data/carDealershipSearchPage";
import { LocalServiceAd } from "../../components/LocalServiceAd";
import Footer from "../../components/Footer";
import { PeopleAskFor } from "../../components/PeopleAskFor";
import { RelatedSearch } from "../../components/RelatedSearch";
import {
  PPCAds,
  localPack,
  organicResult,
  localServiceAds,
  findResult,
} from "../../data/multipleChoiceQuestion";
import Nav from "../../components/Nav";
import { MoreBtn } from "../../components/MoreBtn";
import { ROUTE_LINKS } from "../../routeLinks";

const CarDealershipPage = () => {
  // const ADTYPE = "Car dealerships near me";
  const pageUrl = ROUTE_LINKS.CARDEALERSHIP;
  return (
    <>
      <Nav pageName="Car dealerships near me" />
      <div className="carDealershipSearchPage">
        {/* local service ad */}
        <LocalServiceAd
          showSponsored={true}
          businessName="Car Dealership | Miami
        "
          adCardData={adCardData}
          btnText={"More Car Dealership"}
          choices={localServiceAds}
          adType={"Local Services Ad"}
          pageUrl={pageUrl}
        />
        {/* Sponsored Ad */}
        {sponsoredAdData.map((ad, index) => (
          <SponsoredAds
            key={index}
            showSponsored={true} // Customize these props as needed
            adData={ad} // Pass the dynamic data as a prop
            choices={PPCAds}
            adType={"PPC Ad "}
            pageUrl={pageUrl}
          />
        ))}

        {/* Local Pack */}
        <LocalPack
          title={"Places"}
          isFlexRow={true}
          hasCustomPosition={true}
          locaPackCardData={locaPackCardData}
          btnText={"More Places"}
          isPlace={true}
          choices={localPack}
          adType={"Local Pack"}
          pageUrl={pageUrl}
        />
        <PeopleAskFor peopleAskFor={peopleAskFor} />
        {/* organic results */}
        {organicData.map((ad, index) => (
          <SponsoredAds
            key={index}
            adData={ad}
            choices={organicResult}
            adType={"Organic"}
            pageUrl={pageUrl}
          />
        ))}
        <RelatedSearch
          data={relatedSearchResults}
          adType={"Related Search"}
          pageUrl={pageUrl}
          choices={findResult}
        />
        <MoreBtn btnText={"More results"} />
      </div>
      <Footer location={"Miami, Florida"} />
    </>
  );
};

export default CarDealershipPage;
