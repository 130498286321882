// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.veterinarianSearchPage {
  margin-left: 13%;
  padding-bottom: 3rem; }
`, "",{"version":3,"sources":["webpack://./src/pages/VeterinarianSearchPage/VeterinarianSearchPage.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,oBAAoB,EAAA","sourcesContent":[".veterinarianSearchPage {\n  margin-left: 13%;\n  padding-bottom: 3rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
